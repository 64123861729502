import { TFieldConfig } from 'Routes/MyService/Document/config';

/**
 * Symptoms
 */

// Pain

export const painLocations = [
  'upperLimbLeft',
  'upperLimbRight',
  'upperLimbBoth',
  'lowerLimbLeft',
  'lowerLimbRight',
  'lowerLimbBoth',
  'body',
  'hipOrGenitalia',
  'headArea',
  'widespreadPainSymptoms',
];

export const painFields: Array<TFieldConfig> = [
  {
    name: 'symptom',
    type: 'Select',
    options: ['pain', 'tinnitus', 'other'],
    placeholder: 'myService.ninmt.symptomPlaceholder',
  },
  {
    name: 'symptomStartLocation',
    type: 'Select',
    options: painLocations,
    placeholder: 'myService.ninmt.symptomStartLocationPlaceholder',
  },
  {
    name: 'symptomStartDate',
    type: 'PartialDate',
  },
  {
    name: 'symptomStartDescription',
    type: 'TextArea',
    placeholder: 'myService.ninmt.symptomStartDescriptionPlaceholder',
  },
  {
    name: 'currentMostIntenseSymptoms',
  },
];

export const painIntenseSymptomFields: Array<TFieldConfig> = [
  {
    name: 'symptomQuality',
    type: 'SelectAndAdd',
    options: [
      'superficial',
      'deep',
      'precise',
      'belted',
      'radiating',
      'blunt',
      'stinging',
      'electroshock',
      'tingling',
      'dulling',
      'wavy',
      'pulsating',
      'penetrating',
      'sharp',
      'strident',
      'tearing',
      'pressing',
      'burning',
      'freezing',
      'itchy',
      'smarting',
    ],
    placeholder: 'myService.ninmt.symptomQualityPlaceholder',
    limit: 3,
  },
  {
    name: 'symptomContinuity',
    type: 'Select',
    options: ['continuousConstant', 'continuousAlternating', 'periodic'],
    placeholder: 'myService.ninmt.symptomContinuityPlaceholder',
  },
  {
    name: 'symptomIntensityAverage',
    type: 'NumberField',
    placeholder: '0.0-10.0',
  },
  {
    name: 'symptomIntensityMinMax',
    type: 'NumberRange',
    placeholder: '0.0-10.0',
  },
  {
    name: 'symptomHarmAverage',
    type: 'NumberField',
    placeholder: '0.0-10.0',
  },
  {
    name: 'symptomHarmMinMax',
    type: 'NumberRange',
    placeholder: '0.0-10.0',
  },
];

// Tinnitus

export const tinnitusLocations = ['right', 'left', 'middle'];

export const tinnitusFields: Array<TFieldConfig> = [
  {
    name: 'symptom',
    type: 'Select',
    options: ['pain', 'tinnitus', 'other'],
    placeholder: 'myService.ninmt.symptomPlaceholder',
  },
  {
    name: 'symptomStartLocation',
    type: 'Select',
    options: tinnitusLocations,
    placeholder: 'myService.ninmt.symptomStartLocationPlaceholder',
  },
  // {
  //   name: 'symptomLocation',
  //   type: 'Select',
  //   options: tinnitusLocations,
  //   placeholder: 'myService.ninmt.symptomLocationPlaceholder',
  // },
  {
    name: 'symptomStartDate',
    type: 'PartialDate',
  },
  {
    name: 'symptomStartDescription',
    type: 'TextArea',
    placeholder: 'myService.ninmt.symptomStartDescriptionPlaceholder',
  },
  {
    name: 'currentMostIntenseSymptoms',
  },
];

export const tinnitusIntenseSymptomFields: Array<TFieldConfig> = [
  {
    name: 'symptomQuality',
    type: 'SelectAndAdd',
    options: ['high', 'low', 'ringing', 'musical', 'murmur', 'hissing', 'pulsatile'],
    placeholder: 'myService.ninmt.symptomQualityPlaceholder',
    limit: 3,
  },
  {
    name: 'symptomContinuity',
    type: 'Select',
    options: ['continuousConstant', 'continuousAlternating', 'periodic'],
    placeholder: 'myService.ninmt.symptomContinuityPlaceholder',
  },
  {
    name: 'symptomIntensityAverage',
    type: 'NumberField',
    placeholder: '0.0-10.0',
  },
  {
    name: 'symptomIntensityMinMax',
    type: 'NumberRange',
    placeholder: '0',
  },
  {
    name: 'symptomHarmAverage',
    type: 'NumberField',
    placeholder: '0.0-10.0',
  },
  {
    name: 'symptomHarmMinMax',
    type: 'NumberRange',
    placeholder: '0',
  },
];

// Other

export const otherFields: Array<TFieldConfig> = [
  {
    name: 'symptom',
    type: 'Select',
    options: ['pain', 'tinnitus', 'other'],
    placeholder: 'myService.ninmt.symptomPlaceholder',
  },
  {
    name: 'symptomDescription',
    type: 'TextArea',
    placeholder: 'myService.ninmt.symptomDescriptionPlaceholder',
  },
  {
    name: 'symptomStartDate',
    type: 'PartialDate',
  },
  {
    name: 'symptomStartDescription',
    type: 'TextArea',
    placeholder: 'myService.ninmt.symptomStartDescriptionPlaceholder',
  },
  {
    name: 'currentMostIntenseSymptoms',
  },
];

export const otherIntenseSymptomFields: Array<TFieldConfig> = [
  {
    name: 'symptomContinuity',
    type: 'Select',
    options: ['continuousConstant', 'continuousAlternating', 'periodic'],
    placeholder: 'myService.ninmt.symptomContinuityPlaceholder',
  },
  {
    name: 'symptomIntensityAverage',
    type: 'NumberField',
    placeholder: '0.0-10.0',
  },
  {
    name: 'symptomIntensityMinMax',
    type: 'NumberRange',
    placeholder: '0',
  },
  {
    name: 'symptomHarmAverage',
    type: 'NumberField',
    placeholder: '0.0-10.0',
  },
  {
    name: 'symptomHarmMinMax',
    type: 'NumberRange',
    placeholder: '0',
  },
];

// Episodes (pain, other)

export const episodeFields: Array<TFieldConfig> = [
  {
    name: 'averageAmountOfSymptomEpisodes',
    type: 'Select',
    options: ['1OrLess', '2-5', '6-10', 'moreThan10'],
    placeholder: 'myService.ninmt.averageAmountOfSymptomEpisodesPlaceholder',
  },
  {
    name: 'averageDurationOfSymptomEpisodes',
    type: 'Select',
    options: ['lessThan1Minute', '1-5Minutes', '5-60Minutes', 'moreThan60Minutes'],
    placeholder: 'myService.ninmt.averageDurationOfSymptomEpisodesPlaceholder',
  },
];

/**
 * Sleep
 */

export const sleepFields: Array<TFieldConfig> = [
  {
    name: 'sleepQuantity',
    type: 'NumberField',
    placeholder: '0-10',
  },
  {
    name: 'sleepQuality',
    type: 'NumberField',
    placeholder: '0-10',
  },
];

/**
 * Medication
 */

// Placeholder, since a simplified medication search that could be used in mobile should be implemented (?)
export const medicationFields: Array<TFieldConfig> = [
  {
    name: 'medicationName',
    type: 'TextField',
    placeholder: 'myService.ninmt.medicationNamePlaceholder',
  },
  {
    name: 'medicationDosage',
    type: 'TextArea',
    placeholder: 'myService.ninmt.medicationDosagePlaceholder',
  },
];
