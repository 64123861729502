import * as React from 'react';
import { useIntl } from 'react-intl';

export const getMedicationUnit = (unit?: string, messages?: Record<string, string>) => {
  const formatID = `medication.opts.unit.${unit || ''}`;
  return messages?.[formatID] ? `${messages?.[formatID]}` : unit ? unit : '';
};

const MedicationUnit = ({ unit }: IMedicationUnit) => {
  const { messages } = useIntl();

  const formatID = `medication.opts.unit.${unit || ''}`;
  const localizedString = messages[formatID] ? `${messages[formatID]}` : unit ? unit : '';
  return <span>{localizedString}</span>;
};

interface IMedicationUnit {
  unit?: string;
}

export default MedicationUnit;
