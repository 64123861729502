import * as React from 'react';

/**
 * State exposed from _ConnectionManager_ to React app.
 */
export type TCMReactState = {
  /**
   * Number of in-flight Fetch API requests to "API" endpoint at the moment.
   * This is in contrast to those targeted at "auth" endpoint, where the
   * distinguishing characteristic is that requests are expected to cause
   * changes in auth cookies.
   */
  inflight_fetch_count_api: number;

  /**
   * Number of in-flight Fetch API requests to "auth" endpoint at the moment.
   * These requests are expected to cause changes in auth cookies.
   */
  inflight_fetch_count_auth: number;
};

const cm_react_initial_state: TCMReactState = {
  inflight_fetch_count_api: 0,
  inflight_fetch_count_auth: 0,
};
const CMContext = React.createContext<TCMReactState>(cm_react_initial_state);
let react_hook: (new_state: TCMReactState) => void;
export const ConnectionManagerReactAdapter = ({ children }: { children: React.ReactElement }) => {
  const [state, set_state] = React.useState<TCMReactState>(cm_react_initial_state);

  react_hook = (new_state: TCMReactState) => set_state(new_state);

  return <CMContext.Provider value={state}>{children}</CMContext.Provider>;
};

/**
 * API for React app to use state synced from _ConnectionManager_.
 */
export const useConnections = () => {
  const context = React.useContext(CMContext);
  if (context === undefined) {
    throw new Error('useConnections must be used within ConnectionManagerReactAdapter');
  }
  return context;
};

/**
 * API for _ConnectionManager_ to send state updates to React app.
 */
export const sync_react = (new_state: TCMReactState) => {
  if (react_hook) react_hook(new_state);
};
