import ActionButtonRounded from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { HistoryEventListItem } from 'Components/_NewElements/HistoryEvents';
import { formatPartialDate, nowPartialDate, partialDateFromDate } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '../..';
import { getThisInstanceRecurringEndDate, getThisInstanceRecurringStartDate } from 'neuro-calculation-commons';
import { actions } from 'Store/myapp/actions';
import { useAppDispatch } from 'Store/index';

const getWeekInterval = (repeat: string) => {
  let weekInterval = 86400000 * 7;
  if (repeat.includes('every-nth:2')) {
    weekInterval *= 2;
  } else if (repeat.includes('every-nth:4')) {
    weekInterval *= 4;
  }

  return weekInterval;
};

const RecurringTaskSingle = ({ task, platform, showEndButton, index }: IRecurringTaskSingle) => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, setEditingObj } = myServContext;

  const [openRecurring, setOpenRecurring] = React.useState<boolean>(index === 0);

  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleEndClick = async (taskList: ITaskList) => {
    setLoading(true);
    await actions.updateTasklist({ ...taskList, deadline: nowPartialDate() }, dispatch);
    setLoading(false);
  };

  const tasks = task.tasks?.map((t) => fm(`myService.${platform}.opts.${t}`));
  if (!tasks) return;

  const weekInterval = task.repeat_schedule && getWeekInterval(task.repeat_schedule);

  // Get currently active recurring tasklist send date and deadline

  const currentDeadline =
    task.repeat_schedule && task.end_time
      ? getThisInstanceRecurringEndDate(
          task.start_time,
          task.repeat_schedule,
          task.end_time,
          task.task_duration ?? undefined,
        )
      : undefined;
  const currentSendingDate =
    task.repeat_schedule && task.end_time
      ? getThisInstanceRecurringStartDate(
          task.start_time,
          task.repeat_schedule,
          task.end_time,
          task.task_duration ?? undefined,
        )
      : undefined;

  let previousDeadlineDate: undefined | number = undefined;
  let previousSendingDate: undefined | number = undefined;

  if (weekInterval && currentDeadline) {
    if (task.start_time && currentDeadline - weekInterval < task.start_time) {
      previousDeadlineDate = undefined;
    } else {
      previousDeadlineDate = currentDeadline - weekInterval;
    }
  }

  if (weekInterval && currentSendingDate) {
    if (!task.start_time || currentSendingDate - weekInterval >= task.start_time) {
      previousSendingDate = currentSendingDate - weekInterval;
    }
  }

  // Get next sending date and deadline if possible
  // let nextSendingDate: undefined | number = undefined;
  // let nextDeadlineDate: undefined | number = undefined;
  // if (weekInterval && currentSendingDate) {
  //   if (!rt.end_time || currentSendingDate + weekInterval < rt.end_time) {
  //     nextSendingDate = currentSendingDate + weekInterval;
  //   }
  // }
  // if (weekInterval && currentDeadline && nextSendingDate) {
  //   if (rt.end_time && currentDeadline + weekInterval > rt.end_time) {
  //     // Tasklist end comes before the next calculated deadline, then use end time
  //     nextDeadlineDate = rt.end_time;
  //   } else {
  //     nextDeadlineDate = currentDeadline + weekInterval;
  //   }
  // }

  return (
    <>
      <BlockWrapper
        key={task.id}
        title={tasks.join(', ')}
        contentMargin={1}
        collapse={{
          open: openRecurring,
          collapseHandler: () => setOpenRecurring(!openRecurring),
        }}
      >
        <Container>
          <Item xs={true}>
            <HistoryEventListItem
              titleWidth={5}
              title={'myService.recurrence'}
              value={`${fm(`myService.opts.${task.weeklySurveyInterval}`)}, ${fm('myService.until', {
                until: formatPartialDate(task.deadline),
              })}`}
            />
            <HistoryEventListItem
              titleWidth={5}
              title={'myService.latestDeadline'}
              value={
                previousDeadlineDate
                  ? formatPartialDate(partialDateFromDate(new Date(previousDeadlineDate)))
                  : undefined
              }
            />
            <HistoryEventListItem
              titleWidth={5}
              title={'myService.latestSendingDate'}
              value={
                previousSendingDate ? formatPartialDate(partialDateFromDate(new Date(previousSendingDate))) : undefined
              }
            />
            <HistoryEventListItem
              titleWidth={5}
              title={'myService.nextDeadline'}
              value={currentDeadline ? formatPartialDate(partialDateFromDate(new Date(currentDeadline))) : undefined}
            />
            <HistoryEventListItem
              titleWidth={5}
              title={'myService.nextSendingDate'}
              value={
                currentSendingDate ? formatPartialDate(partialDateFromDate(new Date(currentSendingDate))) : undefined
              }
            />
          </Item>
          {showEndButton ? (
            <Item xs={3.5} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ marginTop: '1rem' }}>
                <ActionButtonRounded
                  text="general.edit"
                  filled={true}
                  uppercase={false}
                  width={9.6}
                  height={3}
                  fontSize={16}
                  onClick={() => {
                    setEditingObj({ type: 'taskList', data: task });
                  }}
                />
                <div style={{ marginTop: '1rem' }} />
                <ActionButtonRounded
                  text="myService.endRecurring"
                  filled={false}
                  uppercase={false}
                  width={9.6}
                  height={3}
                  fontSize={16}
                  onClick={() => handleEndClick(task)}
                  colorScheme="error"
                  loading={loading}
                />
              </div>
            </Item>
          ) : null}
        </Container>
      </BlockWrapper>
      <div style={{ marginBottom: '2rem' }} />
    </>
  );
};

interface IRecurringTaskSingle {
  task: ITaskList;
  platform: Platform;
  showEndButton?: boolean;
  index: number;
}

const RecurringTasksList = ({
  platform,
  recurringTasks,
  showEndButton,
}: {
  platform: Platform;
  recurringTasks: ITaskList[];
  showEndButton?: boolean;
}) => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm } = myServContext;

  return (
    <div style={{ marginBottom: '2rem' }}>
      <div>
        <HistoryRowSubHeader header={fm('myService.recurringTasks')} fontSize="small" />
      </div>
      <div style={{ padding: 1 }}>
        {recurringTasks.map((rt, i) => (
          <RecurringTaskSingle key={rt.id} task={rt} platform={platform} showEndButton={showEndButton} index={i} />
        ))}
      </div>
    </div>
  );
};

export default RecurringTasksList;
