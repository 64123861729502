import { IAddon, IData, IDataPoint, IEvent, IItem } from 'Components/sq-graphics/interfaces';
import { exists, isPartialDate } from 'neuro-utils';
import { getSPMSLorscheiderDate, getSPMSModifiedExpandDate } from 'neuro-calculation-commons';
import { INeuroDocument } from 'neuro-data-structures';
import { capitalize } from '@mui/material';

export const convertMsTypesToTimeline = (
  docs: IDiagnosis[],
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date) || doc.diagnosis !== 'G35' || !doc.typeOfDisease) return undefined;
    const events: IEvent[] = [];

    Array.isArray(doc.typeOfDisease) &&
      doc.typeOfDisease.forEach((t: IMSType) => {
        t.date &&
          events.push({
            date: dateFromPartialUpdateTimeframe(t.date),
            eventType: 'text',
            title: `G35 (${['SP', 'PP', 'RR'].includes(t.type || '') ? t.type : 'NA'})`,
            description: {
              value: t.type ? fm(`diagnosis.msTitle.g35.${t.type}`) : undefined,
              condition: exists(t.type),
            },
          });
      });
    addons.push({
      id: 'diagnosis',
      title: '',
      titleDescription: undefined,
      events: events,
      items: [],
    });

    return;
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertSpmsToTimeline = (
  docs: IDiagnosis[],
  allDocs: INeuroDocument[],
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;
  const addons: IAddon[] = [];
  const lorscheider = getSPMSLorscheiderDate(allDocs) as PartialDate | null;
  const modifiedExpand = getSPMSModifiedExpandDate(allDocs) as PartialDate | null;
  const events: IEvent[] = [];
  if (lorscheider) {
    events.push({
      date: dateFromPartialUpdateTimeframe(lorscheider),
      eventType: 'criteria',
      title: fm('diagnosis.msType.possibleChangeOfMsType'),
      description: fm('diagnosis.msType.lorscheider'),
    });
  }
  if (modifiedExpand) {
    events.push({
      date: dateFromPartialUpdateTimeframe(modifiedExpand),
      eventType: 'criteria',
      title: fm('diagnosis.msType.possibleChangeOfMsType'),
      description: fm('diagnosis.msType.modifiedExpand'),
    });
  }
  if (events.length > 0) {
    addons.push({
      id: 'diagnosis',
      title: fm('diagnosis.title'),
      titleDescription: undefined,
      items: [],
      events: events,
    });
  }
  return addons.length > 0 ? addons : undefined;
};

export const convertRelapseAndEarlySymptomToTimeline = (
  docs: Array<IRelapse | IMsSymptoms>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    const events: IEvent[] = [];

    if (doc._type === 'relapse') {
      const relapseDoc = doc as IRelapse;
      if (!relapseDoc) return;
      const cortisoneOrPlasma = relapseDoc.wasCortisoneGiven === 'yes' || relapseDoc.wasPlasmaGiven === 'yes';
      if (!isPartialDate(relapseDoc.startDate)) return;
      events.push({
        date: dateFromPartialUpdateTimeframe(relapseDoc.startDate),
        eventType: 'symptom',
        title: fm(cortisoneOrPlasma ? 'relapse.tertiaryTitle' : 'relapse.secondaryTitle'),
        description: [
          {
            title: `${fm('relapse.symptoms.title')}`,
            values: (relapseDoc.symptoms ?? []).map((s) => `${fm(`relapse.symptoms.opts.${s}`)}\n`).join(''),
            condition: Array.isArray(relapseDoc.symptoms) && relapseDoc.symptoms.length > 0,
          },
          {
            title: `${fm('relapse.wasCortisoneGiven')}`,
            values: fm(`general.${relapseDoc.wasCortisoneGiven}`),
            condition: exists(relapseDoc.wasCortisoneGiven),
          },
          {
            title: `${fm('relapse.wasPlasmaGiven')}`,
            values: fm(`general.${relapseDoc.wasPlasmaGiven}`),
            condition: exists(relapseDoc.wasPlasmaGiven),
          },
        ],
        priority: cortisoneOrPlasma ? 'high' : 'low',
      });
    }
    if (doc._type === 'msSymptoms') {
      const msSymptomDoc = doc as IMsSymptoms;
      if (!msSymptomDoc) return;
      if (!isPartialDate(msSymptomDoc.symptomOnsetDate)) return;
      const cortisoneOrPlasma = msSymptomDoc.msWasCortisoneGiven === 'yes' || msSymptomDoc.msWasPlasmaGiven === 'yes';
      events.push({
        date: dateFromPartialUpdateTimeframe(msSymptomDoc.symptomOnsetDate),
        eventType: 'symptom',
        title: fm('diagnosis.msEarlySymption'),
        description: [
          {
            title: `${fm('diagnosis.msSymptom.title')}`,
            values: (msSymptomDoc.msSymptoms ?? []).map((s) => `${fm(`diagnosis.msSymptom.opts.${s}`)}\n`).join(''),
            condition: Array.isArray(msSymptomDoc.msSymptoms) && msSymptomDoc.msSymptoms.length > 0,
          },
          {
            title: `${fm('diagnosis.msWasCortisoneGiven')}`,
            values: fm(`general.${msSymptomDoc.msWasCortisoneGiven}`),
            condition: exists(msSymptomDoc.msWasCortisoneGiven),
          },
          {
            title: `${fm('diagnosis.msWasPlasmaGiven')}`,
            values: fm(`general.${msSymptomDoc.msWasPlasmaGiven}`),
            condition: exists(msSymptomDoc.msWasPlasmaGiven),
          },
        ],
        priority: cortisoneOrPlasma ? 'high' : 'low',
      });
    }
    addons.push({
      id: 'relapse',
      title: fm('graph.relapseOrEarlySymptom'),
      titleDescription: undefined,
      items: [],
      events: events,
    });
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertEdssToGraph = (
  docs: Array<INeurologicalStatusAndEDSS>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date) || !exists(d.edssStep)) return;
    d.date &&
      (d.edssStep || d.edssStep === 0) &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.edssStep,
        title: d.edssStep.toString(),
        id: 'edss',
        description: fm(
          `neurologicalStatusAndEDSS.edssStep.opts.${
            d.edssStep === 0 || d.edssStep === 10 ? d.edssStep : d.edssStep?.toFixed(1)
          }`,
        ),
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'edss',
      legend: 'EDSS',
      type: 'lineGraph',
    },
  ];
};

const valueIsLesion = (key: 'lesions' | 'lesionsDelta', value: unknown): value is number | TNumberOfLesions => {
  if (key === 'lesionsDelta') return typeof value === 'number';
  if (
    ['string', 'number'].includes(typeof value) &&
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '10-20', '21-40', '>40'].indexOf(value as string | number) > -1
  ) {
    return true;
  }
  return false;
};

export const convertMriBrainToGraph = (
  docs: IMRI[],
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IData[] | undefined => {
  if (!docs.length) return;

  const lesionTypes = ['total', 'infratentorial', 'supratentorial', 'gadoliniumEnhanced'] as const;
  const dataMap = Object.fromEntries(
    lesionTypes.flatMap((type) => [
      [type, []],
      [`${type}Delta`, []],
    ]),
  ) as Record<string, IDataPoint[]>;

  const lesionTypesToLocaleMap = new Map([
    ['total', 'total'],
    ['infratentorial', 'infra'],
    ['supratentorial', 'supra'],
    ['gadoliniumEnhanced', 'enhanced'],
  ]);

  docs
    .filter((d) => isPartialDate(d.date))
    .forEach((d, i, arr) => {
      const lesionsKey = i === arr.length - 1 ? 'lesions' : 'lesionsDelta';
      if (!d.date) return;
      const date = dateFromPartialUpdateTimeframe(d.date);

      const isDelta = lesionsKey === 'lesionsDelta';

      lesionTypes.forEach((type) => {
        const value =
          type === 'gadoliniumEnhanced'
            ? d.brainT1?.gadoliniumEnhanced?.[lesionsKey]
            : d.brainT2Flair?.[type as keyof typeof d.brainT2Flair]?.[lesionsKey];
        if (valueIsLesion(lesionsKey, value)) {
          dataMap[!isDelta ? type : `${type}Delta`].push({
            date,
            value: !isDelta ? value.toString() : value,
            id: type,
            title: `${value}`,
            pointType: !isDelta ? 'rect' : 'circle',
            legendModifier: isDelta ? { suffix: ' Δ' } : undefined,
          });
        }
      });
    });

  return lesionTypes.flatMap((type) => [
    {
      dataPoints: dataMap[type],
      id: type,
      legend: fm(
        type === 'gadoliniumEnhanced'
          ? 'imaging.brainT1GadoliniumShort'
          : `imaging.brainT2Flair${capitalize(lesionTypesToLocaleMap.get(type)!)}Short`,
      ),
      type: 'lineGraph',
    },
    {
      dataPoints: dataMap[`${type}Delta`],
      id: type,
      legend: fm(
        type === 'gadoliniumEnhanced'
          ? 'imaging.brainT1GadoliniumShort'
          : `imaging.brainT2Flair${capitalize(lesionTypesToLocaleMap.get(type)!)}Short`,
      ),
      type: 'lineGraph',
      useAdditionalScale: true,
    },
  ]);
};

export const convertMriSpinalToGraph = (
  docs: IMRI[],
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IData[] | undefined => {
  if (!docs.length) return;

  const lesionTypes = ['total', 'gadoliniumEnhanced'] as const;
  const dataMap = Object.fromEntries(
    lesionTypes.flatMap((type) => [
      [type, []],
      [`${type}Delta`, []],
    ]),
  ) as Record<string, IDataPoint[]>;

  const lesionTypesToLocaleMap = new Map([
    ['total', 'total'],
    ['gadoliniumEnhanced', 'enhanced'],
  ]);

  docs
    .filter((d) => isPartialDate(d.date))
    .forEach((d, i, arr) => {
      const lesionsKey = i === arr.length - 1 ? 'lesions' : 'lesionsDelta';
      if (!d.date) return;
      const date = dateFromPartialUpdateTimeframe(d.date);

      lesionTypes.forEach((type) => {
        const value =
          type === 'gadoliniumEnhanced'
            ? d.spinalCordT1?.gadoliniumEnhanced?.[lesionsKey]
            : d.spinalCordT2?.[type as keyof typeof d.spinalCordT2]?.[lesionsKey];
        if (valueIsLesion(lesionsKey, value)) {
          dataMap[lesionsKey === 'lesions' ? type : `${type}Delta`].push({
            date,
            value: lesionsKey === 'lesions' ? value.toString() : value,
            id: type,
            title: `${value}`,
            pointType: lesionsKey === 'lesions' ? 'rect' : 'circle',
          });
        }
      });
    });

  return lesionTypes.flatMap((type) => [
    {
      dataPoints: dataMap[type],
      id: type,
      legend:
        fm(
          type === 'gadoliniumEnhanced'
            ? 'imaging.spinalCordT1GadoliniumShort'
            : `imaging.spinalCordT2${capitalize(lesionTypesToLocaleMap.get(type)!)}Short`,
        ) + ' Δ',
      type: 'lineGraph',
    },
    {
      dataPoints: dataMap[`${type}Delta`],
      id: type,
      legend:
        fm(
          type === 'gadoliniumEnhanced'
            ? 'imaging.spinalCordT1GadoliniumShort'
            : `imaging.spinalCordT2${capitalize(lesionTypesToLocaleMap.get(type)!)}Short`,
        ) + ' Δ',
      type: 'lineGraph',
      useAdditionalScale: true,
    },
  ]);
};

type IInflammatoryDiseaseActivityMonitoringDoc =
  | IInflammatoryDiseaseActivityStart
  | IInflammatoryDiseaseActivityStart2024
  | IInflammatoryDiseaseActivityMonitoring
  | IInflammatoryDiseaseActivityMonitoring2024;

export const convertInflammatoryDiseaseActivityToTimeline = (
  docs: Array<IInflammatoryDiseaseActivityMonitoringDoc>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    const events: IEvent[] = [];
    const inflaDoc = doc;
    if (!inflaDoc) return;
    if (!isPartialDate(inflaDoc.date)) return;

    const version = inflaDoc.version === '2024' ? '2024' : '2020';

    const inflammatoryDiseaseActivity = doc.inflammatoryDiseaseActivity;
    if (!inflammatoryDiseaseActivity || inflammatoryDiseaseActivity === 'unknown') return;
    events.push({
      date: dateFromPartialUpdateTimeframe(inflaDoc.date),
      eventType: 'activity',
      title:
        fm('inflammatoryDiseaseActivity.title') + `\n(${fm('inflammatoryDiseaseActivity.careGuidelines')} ${version})`,
      description: [
        {
          title: `${
            inflaDoc._type === 'inflammatoryDiseaseActivityStart'
              ? fm('inflammatoryDiseaseActivity.inflammatoryDiseaseActivityStart')
              : fm('inflammatoryDiseaseActivity.inflammatoryDiseaseActivityMonitoring')
          }`,
          values: fm('inflammatoryDiseaseActivity.opts.' + inflammatoryDiseaseActivity),
          condition: true,
        },
      ],
      priority:
        inflammatoryDiseaseActivity === 'veryActiveMS'
          ? 'high'
          : inflammatoryDiseaseActivity === 'activeMS'
            ? 'normal'
            : inflammatoryDiseaseActivity === 'stableMS'
              ? 'low'
              : undefined,
    });

    addons.push({
      id: 'diseaseActivity',
      title: '',
      titleDescription: undefined,
      items: [],
      events: events,
    });
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertStemcellsToTimeline = (
  docs: Array<IProcedure>,
  fm: (id: string) => string,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return;
  const stemCellEvents: IEvent[] = [];
  const stemCellItems: IItem[] = [];

  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    if (!doc.code) return;

    doc.date &&
      stemCellEvents.push({
        date: dateFromPartialUpdateTimeFrame(doc.date),
        title: fm('medication.otherTreatment.stemCellTransplant'),
        eventType: 'start',
      });
    doc.date &&
      stemCellItems.push({
        start: dateFromPartialUpdateTimeFrame(doc.date),
        title: fm('medication.otherTreatment.stemCellTherapy'),
      });
  });
  return [
    {
      id: '1269349006',
      title: fm('medication.otherTreatment.stemCellTherapy'),
      events: stemCellEvents,
      items: stemCellItems,
    },
  ];
};
