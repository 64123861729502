import { intersperse, values } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import { Container, Item } from '../../../../../../../components/Grid';
import colors from '../../../../../../../config/theme/colors';

import { exists, formatTime, isTime } from 'neuro-utils';
import { isParkinsonInfusionType } from 'Routes/Medication/Document/Form/Medication/RegimenDialog/utils/isParkinsonInfusionType';
import Unit from '../../../../../../../components/Unit';
import { HistoryEventListItem } from 'Components/_NewElements/HistoryEvents';
import MedicationUnit from 'Routes/Medication/utils/medicationUnit';

const InlineDiv = styled('div')({
  display: 'inline-flex',
});

const getStrengthValue = (s?: Array<IStrengths>): number =>
  !s || s.length === 0 ? 1 : parseInt(s[0][Object.keys(s[0])[0]]);

export const StrengthsItem = ({ strengths }: { strengths?: Array<IStrengths> }): JSX.Element => {
  const strs = Array.isArray(strengths) ? strengths.map((s) => intersperse(' / ', values(s))) : [];
  return (
    <React.Fragment>
      {strs.map((s) => (
        <div key={s.toString()}>{s}</div>
      ))}
    </React.Fragment>
  );
};

const DoseItem = ({ dose }: { dose: number }): JSX.Element => (
  <div>
    <span style={{ fontWeight: 600 }}>
      <FormattedMessage id="medication.regimenDose" values={{ n: dose }} />
    </span>
  </div>
);

export const ParkinsonCustomElements = ({
  regimen,
  d,
}: {
  regimen: IRegimenBasics & IRegimenCustom;
  d: IMedication;
}): JSX.Element => {
  const parkinsonCustomFieldType = d && isParkinsonInfusionType(d);

  const transformToMlhValue = (mgh?: number): number | undefined =>
    mgh ? mgh / getStrengthValue(regimen.strengths) : undefined;

  return (
    <>
      {parkinsonCustomFieldType === 'gel' && regimen.infusionDoses?.morningDose && (
        <>
          <div style={{ fontWeight: 'normal' }}>
            <FormattedMessage id="medication.regimenMorningDose" />
          </div>
          <Container style={{ marginBottom: '1rem' }}>
            <Item xs={12}>
              <InlineDiv>
                <Unit unit={<FormattedMessage id="medication.regimenInfusion_ml" />}>
                  {transformToMlhValue(regimen.infusionDoses?.morningDose) ?? '-'}
                </Unit>
              </InlineDiv>
              <InlineDiv>
                <Unit
                  unit={
                    <span>
                      <FormattedMessage id="medication.regimenInfusion_mg" />
                      {')'}
                    </span>
                  }
                >
                  {'(' + (regimen.infusionDoses?.morningDose ?? '-')}
                </Unit>
              </InlineDiv>
            </Item>
          </Container>
        </>
      )}
      {regimen.infusionDoses?.upkeepDoses &&
        regimen.infusionDoses?.upkeepDoses.map((upkeepDose, i) => {
          return (
            <React.Fragment key={`${upkeepDose.upkeepDose}${upkeepDose.upkeepDoseFrom}${upkeepDose.upkeepDoseTo}${i}`}>
              <div style={{ fontWeight: 'normal' }}>
                <FormattedMessage id="medication.regimenUpkeepDose" />
              </div>
              <Container style={{ marginBottom: '1rem' }}>
                <Item xs={4}>
                  <InlineDiv>
                    <Unit unit={<FormattedMessage id="medication.regimenInfusion_mlPerHour" />}>
                      {transformToMlhValue(upkeepDose.upkeepDose) ?? '-'}
                    </Unit>
                  </InlineDiv>
                  <InlineDiv>
                    <Unit
                      unit={
                        <span>
                          <FormattedMessage id="medication.regimenInfusion_mgPerHour" />
                          {')'}
                        </span>
                      }
                    >
                      {'(' + (upkeepDose.upkeepDose ?? '-')}
                    </Unit>
                  </InlineDiv>
                </Item>

                <Item style={{ padding: '0 1rem', fontWeight: 400 }}>
                  <FormattedMessage id="general.clock" />
                </Item>
                <Item>
                  <Unit unit={<FormattedMessage id="medication.regimenInfusion_mgPerHourStarting" />}>
                    {formatTime(upkeepDose.upkeepDoseFrom)}
                  </Unit>
                </Item>
                <Item>
                  <Unit unit={<FormattedMessage id="medication.regimenInfusion_mgPerHourEnding" />}>
                    {formatTime(upkeepDose.upkeepDoseTo)}
                  </Unit>
                </Item>
              </Container>
            </React.Fragment>
          );
        })}
      {regimen.infusionDoses?.additionalDose && (
        <>
          <div style={{ fontWeight: 'normal' }}>
            <FormattedMessage id="medication.regimenAdditionalDose" />
          </div>
          <Container>
            <Item xs={4}>
              <InlineDiv>
                <Unit unit={<FormattedMessage id="medication.regimenInfusion_ml" />}>
                  {transformToMlhValue(regimen.infusionDoses?.additionalDose) ?? '-'}
                </Unit>
              </InlineDiv>
              <InlineDiv>
                <Unit
                  unit={
                    <span>
                      <FormattedMessage id="medication.regimenInfusion_mg" />
                      {')'}
                    </span>
                  }
                >
                  {'(' + (regimen.infusionDoses?.additionalDose ?? '-')}
                </Unit>
              </InlineDiv>
            </Item>
            <Item style={{ padding: '0 4rem' }}>
              <Unit unit={<FormattedMessage id="medication.regimenInfusion_timesPerDay" />}>
                {regimen.infusionDoses?.additionalDoseLimit ?? '-'}
              </Unit>
            </Item>
          </Container>
        </>
      )}
    </>
  );
};

const SubValue = styled('div')({
  '* > &:not(:last-of-type)': {
    marginBottom: '0.7rem',
  },
});

const SubTitleValueItem = ({ title, value }: ITitleValueItemProps): JSX.Element => (
  <SubValue>
    <div style={{ fontSize: '1.4rem', color: colors.darkGray, fontWeight: 400 }}>
      {title ? <FormattedMessage id={title} /> : ''}
    </div>
    <div style={{ fontWeight: 600 }}>{value || '-'}</div>
  </SubValue>
);

interface ITitleValueItemProps {
  title?: string;
  value?: string | JSX.Element | number;
}

export const RegimenOnDemandHistory = ({ regimen }: { regimen: IRegimenBasics & IRegimenOnDemand }): JSX.Element => {
  return (
    <>
      <HistoryEventListItem
        title={'medication.doses.strengths'}
        value={<StrengthsItem strengths={regimen.strengths} />}
      />

      <HistoryEventListItem
        title="medication.regimenDosage"
        value={
          <React.Fragment>
            <SubTitleValueItem
              title="medication.doseSize"
              value={
                regimen.dose ? (
                  <span>
                    {regimen.dose}
                    {regimen?.unit ? (
                      <>
                        {' '}
                        <MedicationUnit unit={regimen.unit} />
                      </>
                    ) : (
                      ''
                    )}
                  </span>
                ) : undefined
              }
            />
            <SubTitleValueItem
              title="medication.maxDoses"
              value={
                regimen.maxDoses && (
                  <span>
                    {regimen.maxDoses}
                    {regimen?.unit ? (
                      <>
                        {' '}
                        <MedicationUnit unit={regimen.unit} />
                      </>
                    ) : (
                      ''
                    )}
                  </span>
                )
              }
            />
          </React.Fragment>
        }
      />
      <HistoryEventListItem title="medication.regimenDetailsOther" value={regimen?.regimenDetailsOther} />
    </>
  );
};
// Todo move to some centralized place for form and history
const getStrengthIndex = (s: IStrengths, pad?: boolean): string => {
  const strs = values(s);

  return pad ? strs.join(' / ') : strs.join('/');
};

const timesAndDosages = (
  dosages: Array<IDosage>,
  regimen: IRegimenBasics & IRegimenDefault,
  strengths: Array<IStrengths>,
): Array<{ from: string; to: string; dosages: Array<JSX.Element> }> => {
  if (dosages.length > 0) {
    const arr = dosages
      .map((d) => ({
        from: formatTime(d.from),
        to: formatTime(d.to),
        dosages: strengths.map(
          (s: IStrengths, i: number): JSX.Element => (
            <Item style={{ textAlign: 'right' }} xs={true} key={i}>
              {exists(d.dosages?.[getStrengthIndex(s)]) ? d.dosages?.[getStrengthIndex(s)] : '-'}
              {exists(d.dosages?.[getStrengthIndex(s)]) && regimen.unit ? (
                <span>
                  {' '}
                  <MedicationUnit unit={regimen.unit} />
                </span>
              ) : (
                ''
              )}
            </Item>
          ),
        ),
      }))
      .sort((a, b) => (a.from < b.from ? -1 : 0));
    return arr;
  } else return [];
};

export const RegimenDefaultHistory = ({ regimen }: { regimen: IRegimenBasics & IRegimenDefault }): JSX.Element => {
  const dosages = regimen.dosages || [];
  const strengths = regimen.strengths || [];

  const hasTimes = !!dosages.find((d) => isTime(d.from));

  return (
    <>
      <HistoryEventListItem title={'medication.doses.strengths'} value={<StrengthsItem strengths={strengths} />} />

      {dosages.length > 0 ? (
        <HistoryEventListItem
          title="medication.regimenDosage"
          fullWidthValue
          value={
            <>
              <Container style={{ fontWeight: 400, padding: '0 0.5rem 0.5rem 0.5rem' }}>
                {hasTimes && (
                  <Item xs={1}>
                    <FormattedMessage id="medication.doses.time" />
                  </Item>
                )}
                {strengths.map((s) => (
                  <Item style={{ textAlign: 'right', fontSize: '1.4rem' }} key={getStrengthIndex(s)} xs={true}>
                    {getStrengthIndex(s, true)}
                  </Item>
                ))}
              </Container>
              {dosages.map((d, i) => (
                <Container
                  key={i}
                  style={{
                    padding: '0.5rem 0.5rem',
                    ...(i % 2 ? {} : { backgroundColor: colors.lightestGray }),
                  }}
                >
                  {hasTimes && (
                    <Item xs={1}>
                      {timesAndDosages(dosages, regimen, strengths)[i].from}
                      {timesAndDosages(dosages, regimen, strengths)[i].to ? ' - ' : ''}
                      {timesAndDosages(dosages, regimen, strengths)[i].to
                        ? timesAndDosages(dosages, regimen, strengths)[i].to
                        : ''}
                    </Item>
                  )}
                  {timesAndDosages(dosages, regimen, strengths)[i].dosages}
                </Container>
              ))}
            </>
          }
        />
      ) : (
        <HistoryEventListItem
          title="medication.doses.title"
          value={
            <span style={{ textTransform: 'lowercase' }}>
              {regimen?.doses ? (
                <>
                  {regimen.unit ? (
                    <span>
                      {`${regimen?.doses} `}
                      <MedicationUnit unit={regimen.unit} />
                    </span>
                  ) : (
                    <FormattedMessage id="medication.regimenDose" values={{ n: regimen?.doses }} />
                  )}
                  {regimen?.repeatNumber && (
                    <>
                      {` `}
                      <FormattedMessage id="medication.regimenDoseInterval" values={{ n: regimen?.repeatNumber }} />
                    </>
                  )}
                </>
              ) : (
                !(regimen?.doses || regimen?.interval) && '-'
              )}
              &nbsp;
              {regimen?.interval && <FormattedMessage id={`medication.opts.${regimen?.interval}`} />}
            </span>
          }
        />
      )}

      {dosages.length > 0 && ( // Show period only if dosages (program) exist
        <HistoryEventListItem
          title="medication.doses.period"
          value={
            <>
              {regimen.periodNumber && regimen.periodUnit && regimen.periodUnit !== undefined ? (
                <>
                  <FormattedMessage id="medication.doses.every" />{' '}
                  {regimen.periodNumber === 1 ? '' : regimen.periodNumber}
                  {regimen.periodNumber === 1 ? ' ' : '. '}
                  <span style={{ textTransform: 'lowercase' }}>
                    <FormattedMessage id={`medication.doses.periodOpts.${regimen.periodUnit}`} />
                  </span>
                </>
              ) : regimen.periodNumber === null ||
                (regimen.periodNumber === undefined && regimen.periodUnit && regimen.periodUnit !== undefined) ? (
                <FormattedMessage id={`medication.doses.periodOpts.${regimen.periodUnit}`} />
              ) : (
                '-'
              )}
            </>
          }
        />
      )}

      <HistoryEventListItem title="medication.regimenDetailsOther" value={regimen?.regimenDetailsOther} />
    </>
  );
};

export const RegimenCustomHistory = ({
  regimen,
  d,
}: {
  regimen: IRegimenBasics & IRegimenCustom;
  d: IMedication;
}): JSX.Element => {
  return (
    <>
      <HistoryEventListItem title="medication.strength" value={<StrengthsItem strengths={regimen.strengths} />} />

      <HistoryEventListItem title="medication.regimenDetails" value={regimen?.regimenDetails} />

      {isParkinsonInfusionType(d) && (
        <HistoryEventListItem
          title="medication.regimenDosage"
          value={<ParkinsonCustomElements regimen={regimen} d={d} />}
        />
      )}

      <HistoryEventListItem title="medication.regimenDetailsOther" value={regimen?.regimenDetailsOther || '-'} />
    </>
  );
};

export const RegimenSingleDoseHistory = ({
  regimen,
}: {
  regimen: IRegimenBasics & IRegimenSingleDose;
}): JSX.Element => {
  return (
    <>
      <HistoryEventListItem title="medication.strength" value={<StrengthsItem strengths={regimen.strengths} />} />

      <HistoryEventListItem
        title="medication.regimenDosage"
        value={
          regimen.dose && regimen.unit ? (
            <span>
              {regimen.dose} <MedicationUnit unit={regimen.unit} />
            </span>
          ) : regimen.dose ? (
            <DoseItem dose={regimen.dose} />
          ) : undefined
        }
      />

      <HistoryEventListItem title="medication.regimenDetailsOther" value={regimen?.regimenDetailsOther || '-'} />
    </>
  );
};

export const RegimenOtherHistory = ({ regimen }: { regimen: IRegimenBasics & IRegimenOther }): JSX.Element => {
  return (
    <>
      <HistoryEventListItem title="medication.strength" value={<StrengthsItem strengths={regimen.strengths} />} />

      <HistoryEventListItem title="medication.regimenOtherInstructions" value={regimen?.instructions || '-'} />

      <HistoryEventListItem title="medication.regimenDetailsOther" value={regimen?.regimenDetailsOther || '-'} />
    </>
  );
};

export const RegimenSmallInfo = ({ firstRegimen, document }: { firstRegimen: Regimen; document: IMedication }) => {
  const TitleValuePair = ({ title, value }: { title?: string; value?: string | JSX.Element }) => {
    return value ? (
      <Container>
        {title && (
          <>
            <Item>
              <FormattedMessage id={title} />
            </Item>
            {value && <Item xs={0.3}></Item>}
          </>
        )}
        <Item style={{ fontSize: '1.6rem', fontWeight: 600 }}>{value}</Item>
      </Container>
    ) : (
      <></>
    );
  };
  return (
    <>
      {firstRegimen?.regimenType === 'single-dose' && (
        <TitleValuePair
          //title="medication.regimenDosage"
          value={
            firstRegimen.dose && firstRegimen.unit ? (
              <span>
                {firstRegimen.dose} <MedicationUnit unit={firstRegimen.unit} />
              </span>
            ) : firstRegimen.dose ? (
              <DoseItem dose={firstRegimen.dose} />
            ) : undefined
          }
        />
      )}
      {firstRegimen?.regimenType === 'onDemand' && (
        <>
          <TitleValuePair
            title="medication.doseSize"
            value={
              firstRegimen.dose ? (
                <span>
                  {firstRegimen.dose}
                  {firstRegimen?.unit ? (
                    <>
                      {' '}
                      <MedicationUnit unit={firstRegimen.unit} />
                    </>
                  ) : (
                    ''
                  )}
                </span>
              ) : undefined
            }
          />
          <TitleValuePair
            title="medication.maxDoses"
            value={
              firstRegimen.maxDoses ? (
                <span>
                  {firstRegimen.maxDoses}
                  {firstRegimen?.unit ? (
                    <>
                      {' '}
                      <MedicationUnit unit={firstRegimen.unit} />
                    </>
                  ) : (
                    ''
                  )}
                </span>
              ) : undefined
            }
          />
        </>
      )}
      {firstRegimen?.regimenType === 'custom' && (
        <div
          className="ellipsis-multiline"
          style={{
            maxWidth: '100%',
          }}
        >
          {firstRegimen.regimenDetails}
          {isParkinsonInfusionType(document) && <FormattedMessage id="medication.seeInfo" />}
        </div>
      )}

      {firstRegimen?.regimenType === 'default' && (
        <div>
          {(firstRegimen.dosages || []).length > 0 ? (
            <div>
              <FormattedMessage id="medication.seeProgrammingInfo" />
            </div>
          ) : (
            <TitleValuePair
              value={
                firstRegimen?.doses ? (
                  <span style={{ textTransform: 'lowercase' }}>
                    {firstRegimen?.doses ? (
                      <>
                        {firstRegimen.unit ? (
                          <span>
                            {`${firstRegimen?.doses} `}
                            <MedicationUnit unit={firstRegimen.unit} />
                          </span>
                        ) : (
                          <FormattedMessage id="medication.regimenDose" values={{ n: firstRegimen?.doses }} />
                        )}
                        {firstRegimen?.repeatNumber && (
                          <>
                            {` `}
                            <FormattedMessage
                              id="medication.regimenDoseInterval"
                              values={{ n: firstRegimen?.repeatNumber }}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      !(firstRegimen?.doses || firstRegimen?.interval) && '-'
                    )}
                    &nbsp;
                    {firstRegimen?.interval && <FormattedMessage id={`medication.opts.${firstRegimen?.interval}`} />}
                  </span>
                ) : undefined
              }
            />
          )}
        </div>
      )}
      {/** @ts-expect-error Static type is not included in regimen interfaces */}
      {firstRegimen.regimenType === 'static' && (
        <div>
          <FormattedMessage id="medication.seeInfo" />
        </div>
      )}
    </>
  );
};
