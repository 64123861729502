import { IMgravisBackground, IParkinsonMobileBackground } from 'neuro-schemas';
import { getPatientAgeInMonths } from '../../../utility/patientInfo';
import {
  exists,
  calculatePartialDateDifferenceInWholeMonths,
  dateFromPartial,
  nowPartialDate,
  sortPartialDate,
  sortTime,
  isPartialDate,
} from 'neuro-utils';
import { compose, equals, filter, keys, map, pickBy, sort, uniq, values } from 'ramda';

/**
 * Get the latest value and date from any of the documents
 * @param  {{[key:string]:any}[]} docs - All documents
 * @param  {string} name - Name of field
 * @returns {{ date: PartialDate; value: any } | undefined} Date and value of the latest value
 */
export const getLatestValue = (
  docs: { [key: string]: any }[],
  name: string,
): { date: PartialDate; value: any } | undefined => {
  let returnValue = undefined;
  if (!docs || docs.length === 0) return returnValue;

  // Sort by date
  docs.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();
  // Get first occurance of the value from previous docs
  docs
    .filter((d) => {
      return d?._editing === false;
    })
    .some((d) => {
      if (exists(d[name])) {
        returnValue = { date: d.date, value: d[name] };
        return true;
      } else return false;
    });
  return returnValue;
};

/**
 * Check that the value exists and it's not null or an object with only a nonexistent value
 * @param  {any} value - Any value to be checked
 * @returns {boolean} True if value exists and isn't null, false if it doesn't exist or is null
 */
export const existsAndIsNotNull = (value: any): boolean => {
  if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
    const objectValue = value.value;
    if (Array.isArray(objectValue)) {
      return objectValue.length > 0 && exists(objectValue[0]) && objectValue[0] !== null;
    }
    return exists(objectValue) && objectValue !== null;
  }
  if (Array.isArray(value)) {
    return value.length > 0 && exists(value[0]) && value[0] !== null;
  }
  return exists(value) && value !== null;
};

/**
 * Check if there is a height value that has either
 * a) been recorded when the patient has been at least 20 years of age, or
 * b) been recorded less than or exactly three months ago
 * @param  {IMeasurement} currentDoc - Measurement height document
 * @param  {PartialDate|undefined} comparisonDate - Optional date to compare height date to instead of current day
 * @returns {boolean} True if the date when the height value has been recorded fulfills the above conditions, false if it doesn't
 */
export const isLatestHeightRecent = (currentDoc: Partial<IMeasurement>, comparisonDate?: PartialDate): boolean => {
  const latestHeightDate =
    currentDoc && currentDoc?.date && existsAndIsNotNull(currentDoc?.value) ? currentDoc?.date : undefined;

  return latestHeightDate
    ? getPatientAgeInMonths(latestHeightDate) >= 240 ||
        (latestHeightDate
          ? calculatePartialDateDifferenceInWholeMonths(latestHeightDate, comparisonDate || nowPartialDate()) <= 3
          : false)
    : false;
};

/**
 * Calculate patient BMI using the following formula: weight (kg) / (height (m))^2
 * @param  {number} height - Patient height
 * @param  {number} weight - Patient weight
 * @returns {number} Patient BMI (kilogram per cubic meter)
 */
export const calculateBMI = (height: number, weight: number): number => {
  return Number((weight / (height / 100) ** 2).toFixed(1));
};

/**
 * Merge all documents to one document that contains the latest values recorded and their respective dates
 * @param  {{[key:string]:any}[]} documents - All documents
 * @returns {{ [key: string]: any }} A document that has the latest values as date-value pairs combined from all documents
 */
export const mergeDocuments = (documents: { [key: string]: any }[]): { [key: string]: any } => {
  const merged = Object.assign({}, Array.isArray(documents) ? documents[0] : {});

  Object.keys(merged).map(
    (key) =>
      key.charAt(0) !== '_' && key !== 'date' && (merged[key] = { date: documents[0]['date'], value: merged[key] }),
  );
  documents && Array.isArray(documents)
    ? documents?.slice(1).map((document) => {
        return Object.keys(document)
          .filter((key) => {
            return key.charAt(0) !== '_';
          })
          .forEach((key) => {
            merged && !existsAndIsNotNull(merged[key])
              ? Object.assign(merged, { [key]: { date: document['date'], value: document[key] } })
              : undefined;
          });
      })
    : undefined;
  return merged;
};

/**
 * Check if timespan in ongoing at this moment.
 * @param startDate beginning of the timespan as PartialDate
 * @param endDate ending of the timespan as PartialDate
 * @returns true or false
 */
export const isOngoingTimespan = (startDate?: PartialDate, endDate?: PartialDate): boolean => {
  const dateNow = new Date();
  const startDateAsDate = startDate ? dateFromPartial(startDate) : undefined;
  const endDateAsDate = endDate ? dateFromPartial(endDate) : undefined;
  if (
    (startDateAsDate && startDateAsDate <= dateNow && endDateAsDate === undefined) ||
    (startDateAsDate === undefined && endDateAsDate && dateNow <= endDateAsDate) ||
    (startDateAsDate && startDateAsDate <= dateNow && endDateAsDate && dateNow <= endDateAsDate)
  ) {
    return true;
  } else return false;
};
/**
 * Check if drivingBan array has a ongoing drivingBan
 * @param banArr Array of drivingBans to be checked
 * @returns 1st found drivingban or undefined
 */
export const getOngoingDrivingBan = (banArr: Array<IDrivingBan>): IDrivingBan | undefined => {
  return banArr.find((ele) => isOngoingTimespan(ele.startDate, ele.endDate));
};

type TMeasurementType =
  | 'weight'
  | 'height'
  | 'bmi'
  | 'headCircumference'
  | 'waistCircumference'
  | 'bpSystolic'
  | 'bpDiastolic'
  | 'pulse'
  | 'oxygenSaturation'
  | 'gaf'
  | 'madrs'
  | 'ybopa'
  | 'ybopt'
  | 'ybocs'
  | 'bpSupineSystolic'
  | 'bpSupineDiastolic'
  | 'bpSittingSystolic'
  | 'bpSittingDiastolic'
  | 'bpStandingSystolic'
  | 'bpStandingDiastolic';

export const codes: { [code: string]: { name: TMeasurementType; unit: string | null } } = {
  '29463-7': { name: 'weight', unit: 'kg' },
  '8302-2': { name: 'height', unit: 'cm' },
  '59574-4': { name: 'bmi', unit: 'kg/m2' },
  '8287-5': { name: 'headCircumference', unit: 'cm' },
  '8280-0': { name: 'waistCircumference', unit: 'cm' },
  '8480-6': { name: 'bpSystolic', unit: 'mmHg' },
  '8462-4': { name: 'bpDiastolic', unit: 'mmHg' },
  '8867-4': { name: 'pulse', unit: 'bpm' },
  '59417-6': { name: 'oxygenSaturation', unit: '%' },
  TMSLP: { name: 'gaf', unit: null },
  '435': { name: 'madrs', unit: null },
  YBOPA: { name: 'ybopa', unit: null },
  YBOPT: { name: 'ybopt', unit: null },
  YBOCS: { name: 'ybocs', unit: null },
  '8461-6': { name: 'bpSupineSystolic', unit: 'mmHg' },
  '8455-8': { name: 'bpSupineDiastolic', unit: 'mmHg' },
  '8459-0': { name: 'bpSittingSystolic', unit: 'mmHg' },
  '8453-3': { name: 'bpSittingDiastolic', unit: 'mmHg' },
  '8460-8': { name: 'bpStandingSystolic', unit: 'mmHg' },
  '8454-1': { name: 'bpStandingDiastolic', unit: 'mmHg' },
};

/**
 * Maps field names to LOINC code object
 * @param {string} name - Field name
 * @returns {{ name: string; unit: string, code: string }|null} LOINC code object
 */
export const fieldNameToCodeObject = (name: string): { name: string; unit: string } | null => {
  const codeObject: { string: { name: string; unit: string } } = pickBy((val) => val.name === name, codes);
  return codeObject ? values(codeObject)?.[0] || null : null;
};

/**
 * Maps field names to LOINC code string
 * @param {string} name - Field name
 * @returns {string|null} LOINC code string
 */
export const fieldNameToCodeString = (name: string): string | null => {
  const codeObject = pickBy((val) => val.name === name, codes);
  return codeObject ? keys(codeObject)?.[0] || null : null;
};

/**
 * Maps care table LOINC codes to a field name
 * @param {string} code - LOINC code
 * @returns {{ name: string; unit: string }|null} field name
 */
export const loincCodeToFieldName = (code: string): { name: string; unit: string | null } | null => codes[code] || null;

const getDates = compose<any[], { date: PartialDate }[], PartialDate[], PartialDate[]>(
  uniq,
  map((d) => d.date),
  sort((a, b) => sortPartialDate(b.date, a.date)),
);

const commonFields = ['weight', 'height'];
const defaultFields = ['bmi'];
const smadmdFields = ['headCircumference'];
const srFields = ['bmi', 'waistCircumference', 'bpSystolic', 'bpDiastolic', 'pulse', 'oxygenSaturation'];
const ninmtFields = ['gaf', 'madrs', 'ybopa', 'ybopt', 'ybocs'];
const parkinsonFields = [
  'bpSupineSystolic',
  'bpSupineDiastolic',
  'bpSittingSystolic',
  'bpSittingDiastolic',
  'bpStandingSystolic',
  'bpStandingDiastolic',
  'pulse',
];

/**
 * Returns measurement field names for a given platform
 * @param {Platform} platform - Platform name
 * @returns {string[]} Field name list
 */
export const getFields = (platform: Platform | string) => {
  let fields = commonFields;
  switch (platform) {
    case 'sma':
    case 'dmd':
      fields = fields.concat(smadmdFields);
      break;
    case 'sleepApnea':
      fields = fields.concat(srFields);
      break;
    case 'ninmt':
      fields = ninmtFields; // Only ninmt fields for now
      break;
    case 'parkinson':
      fields = fields.concat(defaultFields, parkinsonFields);
      break;
    default:
      fields = fields.concat(defaultFields);
      break;
  }
  return fields;
};

export type TCombinedMeasurementData = Array<{
  date: PartialDate;
  data: Array<{
    type: string;
    documents: Array<
      IMeasurement | (IParkinsonMobileBackground & IControlProps) | (ISRMobileBackground & IControlProps)
    >;
  }>;
}>;

/**
 * Group IMeasurements data by date and type
 * [{date, data: [{type, documents: [{code, value, etc}]}]}]
 * @param {IMeasurement[]} docs - Measurement documents
 * @param {Platform|string} platform - Platform
 * @returns {TCombinedMeasurementData} Combined measurements data
 */
export const makeMeasurementsData = (
  docs: Array<IMeasurement>,
  myDocs: Array<(IParkinsonMobileBackground & IControlProps) | (ISRMobileBackground & IControlProps)>,
  platform: Platform | string,
): TCombinedMeasurementData => {
  const dateArray = getDates(
    [...docs, ...myDocs].filter((d) => isPartialDate(d.date)) as Array<
      Required<{ date: PartialDate }> &
        (IMeasurement | ((IParkinsonMobileBackground | ISRMobileBackground | IMgravisBackground) & IControlProps))
    >,
  );
  const dataArray = dateArray.map((date) => ({
    date: date,
    data: getFields(platform).map((type) => ({
      type,
      documents:
        docs
          .filter((doc) => equals(doc.date, date) && 'code' in doc && doc.code === fieldNameToCodeString(type))
          .concat(myDocs.filter((docu) => equals(docu.date, date)))
          .sort((a, b) => ('time' in b && 'time' in a ? sortTime(b.time, a.time) : b._cdate - a._cdate)) || [],
    })),
  }));
  return dataArray;
};

/**
 * Get the most recent value and date for the type of measurement
 * @param {TMeasurementType} type - Value type
 * @param {IMeasurement[]} documents - Measurement docs
 * @returns {{value: string, date: PartialDate}|null} Most recent value and date
 */
export const getLatestMeasurementValueAndDate = (
  type: TMeasurementType,
  documents: IMeasurement[],
): { value?: string; date?: PartialDate } | null => {
  const sortValues = compose<any[], IMeasurement[], IMeasurement[], { value?: string; date?: PartialDate }[]>(
    map((d) => ({ value: d.value, date: d.date })),
    filter((t: IMeasurement) => t.code === fieldNameToCodeString(type)),
    sort((a, b) => sortPartialDate(b.date, a.date) || sortTime(b.time, a.time)),
  );
  return sortValues(documents)?.[0] || null;
};

/**
 *
 * @param field field of which the condition is checked
 * @param doc Background document data
 * @param platform selected platform
 * @returns boolean
 */
export const getFieldCondition = (field: keyof IBackground, doc: IBackground, platform: Platform): boolean => {
  if (platform === 'epilepsy') {
    switch (field) {
      case 'complicationInfo': {
        if (doc.perinatalComplications !== 'yes') return false;
        return true;
      }
      case 'feverCrampsInfo': {
        if (doc.feverCramps !== 'yes') return false;
        return true;
      }
      case 'severity': {
        if (doc.disabledDiagnosis !== 'yes') return false;
        return true;
      }
      case 'relatives':
      case 'relativityInfo': {
        if (doc.epilepsyFirstDegreeRelative !== 'yes') return false;
        return true;
      }
      case 'driversLicenseGroup': {
        if (doc.driversLicense !== 'yes') return false;
        return true;
      }
      case 'occupation': {
        if (
          !Array.isArray(doc.employment) ||
          (!doc.employment?.includes('fullTime') && !doc.employment?.includes('partTime'))
        )
          return false;
        return true;
      }
      case 'partTimeHours': {
        if (!Array.isArray(doc.employment) || !doc.employment?.includes('partTime')) return false;
        return true;
      }
      case 'sickLeaveDays': {
        if (!Array.isArray(doc.employment) || !doc.employment?.includes('sickLeave')) return false;
        return true;
      }
      case 'unemploymentDays': {
        if (!Array.isArray(doc.employment) || !doc.employment?.includes('unemployed')) return false;
        return true;
      }
      case 'retirementType': {
        if (!Array.isArray(doc.employment) || !doc.employment?.includes('retired')) return false;
        return true;
      }
      case 'retirementStart': {
        if (doc.retirementType !== 'disabilityPension') return false;
        return true;
      }
      default: {
        return true;
      }
    }
  }
  if (platform === 'sleepApnea') {
    switch (field) {
      case 'smokingStartYear':
      case 'cigaretteAmountPerDay': {
        if (doc.smoker !== 'currentSmoker' && doc.smoker !== 'formerSmoker') return false;
        return true;
      }
      case 'smokingEndYear': {
        if (doc.smoker !== 'formerSmoker') return false;
        return true;
      }
      case 'annualDrivingKM':
      case 'driversLicenseGroup': {
        if (doc.driversLicense !== 'yes') return false;
        return true;
      }
      case 'drivingBanR1s': {
        if (!Array.isArray(doc.driversLicenseGroup) || !doc.driversLicenseGroup.includes('R1')) return false;
        return true;
      }
      case 'drivingBanR2s':
      case 'professionalDriving': {
        if (!Array.isArray(doc.driversLicenseGroup) || !doc.driversLicenseGroup.includes('R2')) return false;
        return true;
      }
      case 'noProDrivingRequirements': {
        if (doc.professionalDriving !== 'yes') return false;
        return true;
      }
      case 'whichIntoxicants': {
        if (doc.otherIntoxicants !== 'yes') return false;
        return true;
      }
      default: {
        return true;
      }
    }
  }
  if (platform === 'parkinson') {
    switch (field) {
      case 'familyPrevalenceDisease':
      case 'familyMembersParkinson': {
        if (doc.familyPrevalenceParkinson !== 'yes') return false;
        return true;
      }
      case 'siblingDiagnosisAge': {
        if (
          !(
            doc.familyMembersParkinson &&
            doc.familyMembersParkinson.length > 0 &&
            doc.familyMembersParkinson?.some((m) => m === 'siblings' || m === 'twinSibling') === true
          )
        )
          return false;
        return true;
      }
      case 'occupation': {
        if (
          !Array.isArray(doc.employment) ||
          (!doc.employment?.includes('fullTime') && !doc.employment?.includes('partTime'))
        )
          return false;
        return true;
      }
      case 'partTimeHours': {
        if (!Array.isArray(doc.employment) || !doc.employment?.includes('partTime')) return false;
        return true;
      }
      case 'sickLeaveDays': {
        if (!Array.isArray(doc.employment) || !doc.employment?.includes('sickLeave')) return false;
        return true;
      }
      case 'unemploymentDays': {
        if (!Array.isArray(doc.employment) || !doc.employment?.includes('unemployed')) return false;
        return true;
      }
      case 'retirementType': {
        if (!Array.isArray(doc.employment) || !doc.employment?.includes('retired')) return false;
        return true;
      }
      case 'driversLicenseGroup': {
        if (doc.driversLicense !== 'yes') return false;
        return true;
      }
      case 'earlierHeadInjuryYear': {
        if (doc.earlierHeadInjury !== 'yes') return false;
        return true;
      }
      case 'powerOfAttorneyStart': {
        if (doc.powerOfAttorney !== 'yes') return false;
        return true;
      }
      case 'careAllowanceClass': {
        if (doc.careAllowance !== 'yes') return false;
        return true;
      }
      default: {
        return true;
      }
    }
  }
  if (platform === 'ms') {
    switch (field) {
      case 'familyMembersAnamnesisMS': {
        if (doc.familyAnamnesisMS !== 'yes') return false;
        return true;
      }
      case 'employmentOther': {
        if (!Array.isArray(doc.employment) || !doc.employment.includes('other')) return false;
        return true;
      }
      case 'pregnancyDueDate': {
        if (doc.pregnancy !== 'pregnant') return false;
        return true;
      }
      default: {
        return true;
      }
    }
  }
  if (platform === 'dmd' || platform === 'sma') {
    if (
      field === 'familyMembers' &&
      doc[`familyMemberDiagnosed${platform.toUpperCase()}` as keyof IBackground] !== 'yes'
    )
      return false;
    return true;
  }
  if (platform === 'huntington') {
    switch (field) {
      case 'familyMembersAnamnesisHuntington': {
        if (doc.familyAnamnesisHuntington !== 'yes') return false;
        return true;
      }
      case 'relativeDegree2FreeText': {
        if (
          !Array.isArray(doc.familyMembersAnamnesisHuntington) ||
          !doc.familyMembersAnamnesisHuntington.includes('relativeDegree2')
        )
          return false;
        return true;
      }
      case 'retirementDate': {
        if (!Array.isArray(doc.employment) || !doc.employment.includes('disabilityPension')) return false;
        return true;
      }
      default: {
        return true;
      }
    }
  }
  if (platform === 'mgravis') {
    switch (field) {
      case 'cigaretteAmountPerDay':
      case 'smokingStartYear': {
        if (doc?.smoking === 'currentSmoker' || doc?.smoking === 'formerSmoker') return true;
        return false;
      }
      case 'smokingEndYear': {
        if (doc?.smoking === 'formerSmoker') return true;
        return false;
      }
      case 'snusPortions':
      case 'snusStart': {
        if (doc?.snus === 'yes' || doc?.snus === 'earlier') return true;
        return false;
      }
      case 'snusEnd': {
        if (doc?.snus === 'earlier') return true;
        return false;
      }
      case 'occupation': {
        if (
          !Array.isArray(doc.employment) ||
          (!doc.employment?.includes('fullTime') && !doc.employment?.includes('partTime'))
        )
          return false;
        return true;
      }
      case 'partTimeHours': {
        if (!Array.isArray(doc.employment) || !doc.employment?.includes('partTime')) return false;
        return true;
      }
      case 'sickLeaveDays': {
        if (!Array.isArray(doc.employment) || !doc.employment?.includes('sickLeave')) return false;
        return true;
      }
      case 'unemploymentDays': {
        if (!Array.isArray(doc.employment) || !doc.employment?.includes('unemployed')) return false;
        return true;
      }
      case 'retirementType': {
        if (!Array.isArray(doc.employment) || !doc.employment?.includes('retired')) return false;
        return true;
      }
      case 'workAbilityScore.workAbilityComparedToLifetimeBest' as keyof IBackground: {
        if (['disabilityPension', 'pension', 'unemploymentPension'].includes(doc?.retirementType ?? '')) return false;
        return true;
      }
      case 'workAbilityScore.reductionReason' as keyof IBackground:
      case 'workAbilityScore.reductionType' as keyof IBackground: {
        if (
          doc?.workAbilityScore?.workAbilityComparedToLifetimeBest &&
          doc.workAbilityScore.workAbilityComparedToLifetimeBest <= 7
        )
          return true;
        return false;
      }
      case 'workAbilityIndex.selfAssessmentAbilityToWorkIn2Years' as keyof IBackground:
      case 'wantsOccupationalHealthContact': {
        if (
          Array.isArray(doc.employment) &&
          (doc.employment.includes('fullTime') || doc.employment.includes('partTime'))
        ) {
          return true;
        }
        return false;
      }
      default: {
        return true;
      }
    }
  }
  return true;
};
