import * as React from 'react';
import { Container, Item } from 'Components/Grid';
import { StyledTableControls } from '.';
import { Theme, useMediaQuery } from '@mui/material';
import { equals } from 'ramda';
import colors from '../../../config/theme/colors';
import { EventStepperContext } from '..';
import { IEventStepper } from '../definitions';

const TableView = ({ normalizedProps, propsByType, types }: IOwnProps): JSX.Element | null => {
  const eventStepperContext = React.useContext(EventStepperContext);
  const { thisEvents, editingEvent } = eventStepperContext;

  const isGroup = propsByType && Array.isArray(types) && types.length > 0;

  const propsToUse = (type?: string) => {
    if (!isGroup) return normalizedProps;
    return type ? propsByType[type] : propsByType[types[0]];
  };

  const { tableContent, tableContentFilter, stepLabelText } = propsToUse();

  // Track page width and breakpoints
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.between(0, 1401));

  // Get suitable event limit displayed in table view based on page width
  const getEventLimit = () => {
    if (md) return 2;
    return 3;
  };

  // Used to change the range of events displayed in table view
  const [eventRange, setEventRange] = React.useState<[number, number]>([0, getEventLimit()]);

  const addingNewEvent = editingEvent === true;

  const tableEvents = Array.isArray(thisEvents)
    ? (tableContentFilter && tableContentFilter.eventFilter
        ? tableContentFilter.eventFilter(thisEvents)
        : thisEvents
      ).filter(
        (_: any, i: number) =>
          (addingNewEvent && i !== 0) || // Dont show event being created in the list
          !addingNewEvent,
      )
    : [];

  // Set event range to be within limits if page width and therefore event limit changes
  React.useEffect(() => {
    if (Array.isArray(tableEvents)) {
      const diff = getEventLimit() - (eventRange[1] - eventRange[0]);
      if (diff !== 0 && tableEvents.length >= getEventLimit() + 1) {
        if (diff > 0 && eventRange[1] + 1 === tableEvents.length) setEventRange([eventRange[0] - diff, eventRange[1]]);
        else setEventRange([eventRange[0], eventRange[1] + diff]);
      }
    }
  }, [getEventLimit()]);

  // If the length of tableEvents is more than 4, set eventRange to the "end" of tableEvents by default
  React.useEffect(() => {
    Array.isArray(tableEvents) &&
      tableEvents.length > getEventLimit() &&
      !equals(eventRange, [tableEvents.length - (getEventLimit() + 1), tableEvents.length - 1]) &&
      setEventRange([tableEvents.length - (getEventLimit() + 1), tableEvents.length - 1]);
  }, [Array.isArray(tableEvents) && tableEvents.length]);

  const changeEventRange = (change: number) => (): void => {
    let newRange: React.SetStateAction<[number, number]> = [...eventRange];
    newRange = [newRange[0] + change, newRange[1] + change];
    setEventRange(newRange);
  };

  return tableContent ? (
    <React.Fragment>
      <Container
        alignItems="center"
        style={{
          backgroundColor: colors.lightGray,
          fontWeight: 600,
          height: '5rem',
          marginBottom: '1rem',
        }}
      >
        <Item xs={3} style={{ paddingLeft: '2rem' }}>
          {tableContentFilter && tableContentFilter.input}
        </Item>
        {tableEvents.slice(eventRange[0], eventRange[1] + 1).map((e: any, i: number, arr: any[]) => (
          <Item key={i} xs={i === arr.length - 1 ? 1 : 2}>
            {stepLabelText(e)}
          </Item>
        ))}
        <Item xs={true}>
          {tableEvents.length > 1 && (
            <StyledTableControls
              eventRange={eventRange}
              tableEvents={tableEvents}
              changeEventRange={changeEventRange}
            />
          )}
        </Item>
      </Container>
      {tableContent(tableEvents.slice(eventRange[0], eventRange[1] + 1))}
    </React.Fragment>
  ) : null;
};

interface IOwnProps {
  normalizedProps: IEventStepper;
  propsByType?: {
    [key: string]: IEventStepper;
  };
  types?: string[];
}

export default TableView;
