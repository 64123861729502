import * as React from 'react';
import { StepIconProps, StepLabel, StepContent, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { ArrowDropDown, ArrowRight, MoreVert } from '@mui/icons-material';

import colors from '../../../config/theme/colors';
import { exists } from 'neuro-utils';
import ActionButton from '../../ActionButton';
import { Container, Item } from '../../Grid';

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { fm } from 'Components/FormatMessage';

export const Connector = ({ completed }: { completed?: boolean }): JSX.Element => (completed ? <span /> : <span />);

/**
 * Renders an icon indicating whether a step in a stepper is expanded (open) or collapsed (closed).
 * Displays a downward arrow when the step is active (open) and a rightward arrow when inactive (closed).
 *
 * @param {StepIconProps} props - The properties for determining the step's state
 * @returns {JSX.Element} - The corresponding icon wrapped in a styled container
 */
export const IconChooser = (props: StepIconProps): JSX.Element => {
  const { active } = props;
  return (
    <Container style={{ width: '5rem' }} justifyContent="center" alignItems="center">
      <Item>
        {active ? (
          <ArrowDropDown color="primary" style={{ display: 'block' }} />
        ) : (
          <ArrowRight color="disabled" style={{ display: 'block' }} />
        )}
      </Item>
    </Container>
  );
};

/** Header bar - the area that is clicked to open the step */
export const StyledStepLabel = styled(StepLabel)({
  height: '5rem',
  backgroundColor: colors.lightGray,
  cursor: 'pointer !important',
});

/** Header text - the text inside the step label */
export const StyledLabelText = styled('div')({
  color: `${colors.primaryText} !important`,
  fontSize: '1.6rem',
  fontWeight: 600,
  maxHeight: '4.2rem', // This can fit exactly 2 lines
  overflow: 'hidden',
});

/** Step content */
export const StyledStepContent = styled(StepContent, {
  shouldForwardProp: (prop) => prop !== 'editing',
})(({ editing }: { editing?: boolean }) => ({
  marginLeft: `${!editing ? '5rem' : '0rem'} !important`,
  marginTop: `${!editing ? '2rem' : '0rem'} !important`,
  padding: '0rem 0rem 2rem 0rem !important',
  border: '0 !important',
}));

const StyledValueRow = styled('div')({
  marginTop: '1rem',
  '* > &:first-of-type': {
    marginTop: 0,
  },
});

const StyledInputValueRow = styled('div')({
  marginBottom: '2rem',
});

/**
 * Renders a header-value pair for event steppers in 'view' mode.
 * Used to display static values with a corresponding label.
 * See `StepperHeaderFormInputPair` for form-based elements.
 *
 * @param {JSX.Element | string} header - Localized string or element representing the label
 * @param {any} value - The value to display
 * @returns {JSX.Element} - A styled row containing the header and value
 */
export const StepperHeaderValuePair = ({ header, value }: IStepperHeaderValuePair): JSX.Element => (
  <StyledValueRow>
    <Container>
      <Item xs={5} style={{ paddingRight: '2rem' }}>
        {header}
      </Item>
      <Item xs={true} style={{ fontWeight: 600 }}>
        {exists(value) ? value : '-'}
      </Item>
    </Container>
  </StyledValueRow>
);

interface IStepperHeaderValuePair {
  header: JSX.Element | string;
  value?: any;
}

/**
 * Renders a header and input field pair for event steppers in 'edit' mode.
 * Used to display a form input alongside its corresponding label.
 *
 * @param {JSX.Element | string} header - Localized string or element representing the label
 * @param {JSX.Element} input - The input field component
 * @returns {JSX.Element} - A styled row containing the header and input field
 */
export const StepperHeaderFormInputPair = ({ header, input }: IStepperHeaderFormInputPair): JSX.Element => (
  <StyledInputValueRow>
    <Container>
      <Item xs={12} md={5} style={{ paddingRight: '2rem' }}>
        {header}
      </Item>
      <Item xs={true}>{input}</Item>
    </Container>
  </StyledInputValueRow>
);

interface IStepperHeaderFormInputPair {
  header: JSX.Element | string;
  input: JSX.Element;
}

/** Static header bar */
export const StandAloneHeader = styled('div')({
  height: '5rem',
  backgroundColor: colors.lightGray,
  padding: '0 0 0 5rem',
  fontWeight: 600,
  cursor: 'default',
});

/** Static step content */
export const StandAloneContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'editing',
})(({ editing }: { editing?: boolean }) => ({
  marginLeft: `${!editing ? '5rem' : '0rem'} !important`,
  marginTop: `${!editing ? '2rem' : '0rem'} !important`,
  padding: '0rem 0rem 2rem 0rem !important',
  border: '0 !important',
}));

const StyledMenuIcon = styled(MoreVert)({
  display: 'block !important',
  color: '#6c96ae',
  cursor: 'pointer',
});

/**
 * Renders header bar controls, including an edit button and a dropdown menu.
 * Provides actions for editing and deleting an event step.
 *
 * @param {number} index - The index of the event step
 * @param {string} type - The type of the event step (optional)
 * @param {(index: number, type?: string) => (e: React.MouseEvent) => void} setEditing - Function to enable editing mode
 * @param {(e: React.MouseEvent<SVGElement>) => void} toggleMenu - Function to toggle the menu
 * @param {{ anchor: HTMLElement; index: number } | null} anchor - The anchor element for the menu and its associated index
 * @param {(index: number, type?: string) => (e: React.MouseEvent) => void} openDeleteDialog - Function to open the delete confirmation dialog
 * @returns {JSX.Element} - The header controls containing the edit button and menu
 */
export const HeaderControls = ({
  index,
  type,
  setEditing,
  toggleMenu,
  anchor,
  openDeleteDialog,
}: IHeaderControls): JSX.Element => {
  const menuAnchor = anchor && anchor.index === index ? anchor : null;
  return (
    <Container justifyContent="flex-end" alignItems="center">
      <Item style={{ marginRight: '2rem' }}>
        <ActionButton text={'general.edit'} onClick={setEditing(index, type)} width={10} height={3} fontSize={14} />
      </Item>
      <Item style={{ marginRight: '2rem' }}>
        <StyledMenuIcon onClick={toggleMenu} />
      </Item>
      <Menu id="eventsteppermenu" anchorEl={menuAnchor?.anchor} open={Boolean(menuAnchor?.anchor)} onClose={toggleMenu}>
        <MenuItem onClick={openDeleteDialog(index, type)}>{fm('general.deletionTitle')}</MenuItem>
      </Menu>
    </Container>
  );
};

export const StyledViewSelector = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }: { active?: boolean }) => ({
  color: `${colors.primary}`,
  userSelect: 'none',
  textDecoration: active ? 'none' : 'underline',
  fontWeight: active ? 'bold' : 'normal',
  cursor: active ? 'default' : 'pointer',
}));

const StyledControl = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }: { active?: boolean }) => ({
  fontSize: '1.8rem',
  color: active ? colors.primary : colors.gray,
  cursor: active ? 'pointer' : 'default',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'baseline',
  transform: 'scaleY(1.25)',
}));

/**
 * Renders table navigation controls for paginating event data.
 * Provides buttons to move the visible event range forward or backward in increments.
 *
 * @param {[number, number]} eventRange - The current range of displayed events (start index, end index).
 * @param {any[]} tableEvents - The full list of table events.
 * @param {(change: number) => () => void} changeEventRange - Function to update the event range.
 * @returns {JSX.Element} - The styled table controls with navigation buttons.
 */
export const StyledTableControls = ({
  eventRange,
  tableEvents,
  changeEventRange,
}: {
  eventRange: [number, number];
  tableEvents: any[];
  changeEventRange: (change: number) => () => void;
}): JSX.Element => {
  const negativeModifier = eventRange[0] - 6 >= 0 ? -6 : -eventRange[0];
  const positiveModifier = eventRange[1] + 6 <= tableEvents.length - 1 ? 6 : tableEvents.length - 1 - eventRange[1];
  return (
    <React.Fragment>
      <Item
        xs={true}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingRight: '2rem',
          color: colors.tertiaryText,
          userSelect: 'none',
        }}
      >
        <StyledControl
          active={eventRange[0] > 0}
          onClick={eventRange[0] > 0 ? changeEventRange(negativeModifier) : (): string => ''}
        >
          <KeyboardDoubleArrowLeftIcon />
        </StyledControl>
        &nbsp;
        <StyledControl active={eventRange[0] > 0} onClick={eventRange[0] > 0 ? changeEventRange(-1) : (): string => ''}>
          <KeyboardArrowLeftIcon />
        </StyledControl>
        &nbsp;&nbsp;&nbsp;
        {`${eventRange[0] + 1}-${tableEvents.length < eventRange[1] + 1 ? tableEvents.length : eventRange[1] + 1} / ${
          tableEvents.length
        }`}
        &nbsp;&nbsp;&nbsp;
        <StyledControl
          active={eventRange[1] < tableEvents.length - 1}
          onClick={eventRange[1] < tableEvents.length - 1 ? changeEventRange(1) : (): string => ''}
        >
          <KeyboardArrowRightIcon />
        </StyledControl>
        &nbsp;
        <StyledControl
          active={eventRange[1] < tableEvents.length - 1}
          onClick={eventRange[1] < tableEvents.length - 1 ? changeEventRange(positiveModifier) : (): string => ''}
        >
          <KeyboardDoubleArrowRightIcon />
        </StyledControl>
      </Item>
    </React.Fragment>
  );
};

interface IHeaderControls {
  index: number;
  type?: string;
  setEditing: (index: number, type?: string) => (e: React.MouseEvent) => void;
  toggleMenu: (e: React.MouseEvent<SVGElement>) => void;
  anchor: { anchor: HTMLElement; index: number } | null;
  openDeleteDialog: (index: number, type?: string) => (e: React.MouseEvent) => void;
}
