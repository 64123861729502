import { remove } from 'ramda';
import { createID } from '../../../utility/appendIDs';
import { sortTime, sortPartialDate } from 'neuro-utils';
import { INeuroDocument } from 'neuro-data-structures';
import { IEventStepper } from '../definitions';

/**
 * Creates a new event item and adds it to the specified event array in formData.
 * If the event array does not exist, it initializes a new array with the event.
 * The event can be modified using an optional creationMutator function.
 *
 * @param {string} name - The key for the event array in the formData document
 * @param {IFormData<any>} formData - The form data object containing the event array
 * @param {(event: any) => any} creationMutator - Optional function to modify the event before adding it
 * @param {object} [defaultValues={}] - Default values to include in the new event
 * @returns {void}
 */
export const createEvent =
  (name: string, formData: IFormData<any>, creationMutator?: (event: any) => any, defaultValues = {}) =>
  (): void => {
    if (!formData.document[name] || formData.document[name].length === 0) {
      let newEvent = { id: createID(), ...defaultValues };
      newEvent = creationMutator ? creationMutator(newEvent) : newEvent;
      formData.onChange && formData.onChange({ [name]: [newEvent] });
    } else {
      const array = structuredClone(formData.document[name]) ?? [];

      let newEvent = { id: createID(), ...defaultValues };
      newEvent = creationMutator ? creationMutator(newEvent) : newEvent;
      array.unshift(newEvent);
      formData.onChange && formData.onChange({ [name]: array });
    }
  };

/**
 * Sorts an array of events by date, prioritizing `createDate` first, then sorting by `startDate/startTime` or `date/time` if applicable.
 *
 * @param {unknown} events - The list of events to be sorted
 * @param {string} [dateFieldName] - Optional field name to use for date sorting
 * @returns {void}
 */
const sortByDate = (events: unknown, dateFieldName?: string): void => {
  if (events && Array.isArray(events)) {
    events
      .sort((s1, s2) => (s2?.createDate ?? 0) - (s1?.createDate ?? 0))
      .sort((n1, n2) => {
        // Some events might use startDate and startTime instead of date and time
        if (dateFieldName) return sortPartialDate(n2[dateFieldName] as PartialDate, n1[dateFieldName] as PartialDate);
        if (n1.startDate) {
          if (n1.startTime) return sortPartialDate(n2.startDate, n1.startDate) || sortTime(n2.startTime, n1.startTime);
          return sortPartialDate(n2.startDate, n1.startDate);
        }

        if (n1.time) return sortPartialDate(n2.date, n1.date) || sortTime(n2.time, n1.time);
        return sortPartialDate(n2.date, n1.date);
      });
  }
};

/**
 * Sorts an array of events by date and updates the formData with the sorted array.
 *
 * @param {string} name - The key for the event array in the formData document
 * @param {IFormData<any>} formData - The form data object containing the event array
 * @param {string} [dateFieldName] - Optional field name to use for date sorting
 * @returns {void}
 */
export const sortByDateAndSave = (name: string, formData: IFormData<any>, dateFieldName?: string): void => {
  const events: IFormData<any>['document'] = structuredClone(formData.document[name]);
  sortByDate(events, dateFieldName);
  formData.onChange && formData.onChange({ [name]: events });
};

/**
 * Sorts an array of combined events based on creation date and then by their respective date fields.
 *
 * @param {any[]} combinedEvents - Array of combined events from different sources
 * @param {(type?: string) => IEventStepper} propsToUse - Function to determine date field names based on event type
 * @returns {any[]} - The sorted array of events
 */
export const combinedEventsToSorted = (combinedEvents: any[], propsToUse: (type?: string) => IEventStepper) =>
  combinedEvents
    // Sorting by createDate
    .toSorted((s1, s2) => (s2.createDate ?? 0) - (s1.createDate ?? 0))
    .sort((n1, n2) => {
      const typeProps1 = propsToUse(n1._type);
      const typeProps2 = propsToUse(n2._type);

      // Determine the correct date field names
      const dateFieldName1 = typeProps1.dateFieldName ?? (n1.startDate ? 'startDate' : 'date');
      const dateFieldName2 = typeProps2.dateFieldName ?? (n2.startDate ? 'startDate' : 'date');

      // Sorting logic when different date field names have been specified
      if (typeProps1.dateFieldName || typeProps2.dateFieldName) {
        if (
          (dateFieldName1 === 'startDate' || dateFieldName2 === 'startDate') &&
          (n1.startTime || n2.startTime || n1.time || n2.time)
        ) {
          return (
            sortPartialDate(n2[dateFieldName2], n1[dateFieldName1]) ||
            sortTime(n2.startTime ?? n2.time, n1.startTime ?? n1.time)
          );
        }
        return (
          sortPartialDate(n2[dateFieldName2], n1[dateFieldName1]) ||
          sortTime(n2.startTime ?? n2.time, n1.startTime ?? n1.time)
        );
      }

      // General sorting logic
      const date1 = n1.startDate ?? n1.date;
      const date2 = n2.startDate ?? n2.date;

      if (n1.startTime || n2.startTime) return sortPartialDate(date2, date1) || sortTime(n2.startTime, n1.startTime);

      if (n1.time || n2.time) return sortPartialDate(n2.date, n1.date) || sortTime(n2.time, n1.time);

      return sortPartialDate(n2.date, n1.date);
    });

export type TDeleteProps = Pick<INeuroDocument, 'removeTS' | 'removeInfo'>;

/**
 * Deletes an event from the event array in formData.
 * If deleteProps are provided, the event is marked as removed instead of being deleted.
 *
 * @param {number} i - Index of the event to delete
 * @param {string} name - The key for the event array in the formData document
 * @param {IFormData<any>} formData - The form data object containing the event array
 * @param {TDeleteProps} [deleteProps] - Optional properties to mark an event as deleted
 * @returns {void}
 */
export const deleteEvent =
  (i: number, name: string, formData: IFormData<any>, deleteProps?: TDeleteProps) => (): void => {
    const events = structuredClone(formData.document[name]) || [];

    if (formData.onChange) {
      if (deleteProps) {
        const newEvents = events;
        events[i] = { ...events[i], ...deleteProps };
        formData.onChange({ [name]: newEvents });
      } else {
        const newEvents = remove(i, 1, events);
        formData.onChange({ [name]: newEvents });
      }
    }
  };

/**
 * Updates an event in the event array and applies sorting if the date field is modified.
 *
 * @param {number} index - Index of the event to update
 * @param {string} eventName - The key for the event array in formData
 * @param {string} [dateFieldName='date'] - The field name to check for date changes
 * @param {IFormData<any>} formData - The form data object containing the event array
 * @param {any} editingEvents - The current list of editing events
 * @param {Function} setEditingIndex - Function to update the editing index after sorting
 * @param {(event: any, name?: string, value?: any) => any} [onChangeMutator] - Optional function to modify event values before updating
 * @returns {void}
 */
export const onChangeEvent =
  (
    index: number,
    eventName: string,
    dateFieldName = 'date',
    formData: IFormData<any>,
    editingEvents: any,
    setEditingIndex: any,
    onChangeMutator?: (event: any, name?: string, value?: any) => any,
  ) =>
  (onChangeValues: TOnChangeValues): void => {
    const onChangeName = Object.keys(onChangeValues)[0]; // First value should be the main field
    const onChangeValue = onChangeValues[onChangeName];

    // Update current event item and then update all events
    const allEvents = structuredClone(formData.document?.[eventName]) || [];
    const thisEvent = allEvents[index];
    let thisNewEvent = { ...thisEvent, ...onChangeValues };

    if (onChangeMutator) thisNewEvent = onChangeMutator(thisNewEvent, onChangeName, onChangeValue);

    allEvents[index] = thisNewEvent;

    if (editingEvents) {
      editingEvents = structuredClone(allEvents);
    }

    // If date value is changed, sort actual form data in the background while keeping temporary editing data in initial order
    if (onChangeName === dateFieldName) {
      sortByDate(allEvents);
      const newEditingIndex = allEvents.findIndex((e: any) => e.id === thisNewEvent.id);
      setEditingIndex(newEditingIndex);
    }
    formData.onChange && formData.onChange({ [eventName]: allEvents });
  };
