import { TFieldConfig } from 'Routes/MyService/Document/config';

/**
 * Symptoms
 */

export const symptomFields: Array<TFieldConfig> = [
  {
    name: 'averageInAWeek',
    type: 'NumberField',
    placeholder: '0.0-10.0',
  },
  {
    name: 'lowestHighestInAWeek',
    type: 'NumberRange',
    placeholder: '0.0-10.0',
  },
  {
    name: 'averageHarmInAWeek',
    type: 'NumberField',
    placeholder: '0.0-10.0',
  },
  {
    name: 'lowestHighestHarmInAWeek',
    type: 'NumberRange',
    placeholder: '0.0-10.0',
  },
];

/**
 * Episodes
 */

export const episodeFields: Array<TFieldConfig> = [
  {
    name: 'episodesInAWeek',
    type: 'Select',
    options: ['1OrLess', '2-5', '6-10', 'moreThan10'],
    placeholder: 'myService.ninmt.episodesInAWeekPlaceholder',
  },
  {
    name: 'episodesDuration',
    type: 'Select',
    options: ['lessThan1Minute', '1-5Minutes', '5-60Minutes', 'moreThan60Minutes'],
    placeholder: 'myService.ninmt.episodesDurationPlaceholder',
  },
];

/**
 * Sleep
 */

export const sleepFields: Array<TFieldConfig> = [
  {
    name: 'sleepQuantity',
    type: 'NumberField',
    placeholder: '0-10',
  },
  {
    name: 'sleepQuality',
    type: 'NumberField',
    placeholder: '0-10',
  },
];

/**
 * GIC and additional information
 */

export const gicAndAdditionalInformationFields: Array<TFieldConfig> = [
  {
    name: 'treatmentEffectivenessEstimate',
    type: 'Select',
    options: [-3, -2, -1, 0, 1, 2, 3],
    placeholder: 'myService.ninmt.treatmentEffectivenessEstimatePlaceholder',
  },
  {
    name: 'gic',
    type: 'TextArea',
    // placeholder: '',
  },
];

/**
 *  Medication
 */
export const medicationFields: Array<TFieldConfig> = [
  {
    name: 'medicationName',
    type: 'TextField',
    placeholder: 'myService.ninmt.medicationNamePlaceholder',
  },
  {
    name: 'medicationDosage',
    type: 'TextArea',
    placeholder: 'myService.ninmt.medicationDosagePlaceholder',
  },
  {
    name: 'onDemandDosingWithinWeek',
    type: 'TextArea',
    placeholder: 'myService.ninmt.onDemandDosingWithinWeek',
  },
];

export const additionalInformationField: Array<TFieldConfig> = [
  {
    name: 'additionalDetails',
    type: 'TextArea',
    // placeholder: '' ,
  },
];
