import * as React from 'react';
import { Container, Item } from 'Components/Grid';
import { StandAloneHeader, StyledViewSelector } from '.';
import colors from '../../../config/theme/colors';
import { fm } from 'Components/FormatMessage';
import { EventStepperContext } from '..';
import { IEventStepper, TTableView } from '../definitions';

const ViewSelector = ({ normalizedProps, propsByType, types }: IOwnProps): JSX.Element => {
  const eventStepperContext = React.useContext(EventStepperContext);
  const { editingEvent, activeView, setActiveView } = eventStepperContext;

  const isGroup = propsByType && Array.isArray(types) && types.length > 0;

  const propsToUse = (type?: string) => {
    if (!isGroup) return normalizedProps;
    return type ? propsByType[type] : propsByType[types[0]];
  };

  const { previousEventsTextHeader } = propsToUse();

  const addingNewEvent = editingEvent === true;

  return (
    <Container style={{ height: '3rem' }}>
      {typeof editingEvent !== 'number' && !addingNewEvent && (
        <React.Fragment>
          <Item xs={5} style={{ color: colors.tertiaryText }}>
            {fm(previousEventsTextHeader)}
          </Item>
          <Item xs={true}>
            <StandAloneHeader style={{ backgroundColor: 'transparent' }}>
              <Container>
                <Item xs={6} />
                <Item xs={6} style={{ textAlign: 'right' }}>
                  <Container justifyContent="flex-end">
                    <Item style={{ fontWeight: 'normal' }}>
                      {['list', 'table'].map((v, i) => (
                        <React.Fragment key={v}>
                          <StyledViewSelector
                            style={{ paddingRight: i === 0 ? '0.5rem' : undefined }}
                            onClick={(): void => setActiveView(v as TTableView)}
                            active={activeView === v}
                          >
                            {fm(`general.${v}`)}
                          </StyledViewSelector>
                          {i > 0 && ' '}
                        </React.Fragment>
                      ))}
                    </Item>
                  </Container>
                </Item>
              </Container>
            </StandAloneHeader>
          </Item>
        </React.Fragment>
      )}
    </Container>
  );
};

interface IOwnProps {
  normalizedProps: IEventStepper;
  propsByType?: {
    [key: string]: IEventStepper;
  };
  types?: string[];
}

export default ViewSelector;
