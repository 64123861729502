import { sync_react, TCMReactState } from './ConnectionManagerReactAdapter';

interface IConnectionManager {
  connect_websocket(url: URL): WebSocketAbortable;
}

interface IAbortable {
  _aborted: boolean;

  abort(): void;
}

type IWebSocketAbortable = IAbortable & WebSocket;

class WebSocketAbortable extends globalThis.WebSocket implements IWebSocketAbortable {
  public _aborted: boolean;

  constructor(...super_args: ConstructorParameters<typeof WebSocket>) {
    super(...super_args);
    this._aborted = false;
  }

  public abort(): void {
    super.close();
    this._aborted = true;
  }
}

type TTrackedFetchApiRequest = {
  id: string;
  is_auth: boolean;
  url: string;
};

/**
 * Manages outbound HTTP requests including WebSocket handshakes.
 */
class ConnectionManager implements IConnectionManager {
  static _singleton: null | ConnectionManager = null;

  readonly #_debug_log: boolean;
  readonly #_websockets_abortable: Set<IWebSocketAbortable>;

  #_abort_controller: null | { ctl: AbortController; requests: Set<TTrackedFetchApiRequest> };

  constructor({ debug_log }: { debug_log: boolean }) {
    if (ConnectionManager._singleton !== null) {
      throw new Error('ConnectionManager should only be instantiated once');
    } else {
      ConnectionManager._singleton = this;
    }

    this.#_abort_controller = null;
    this.#_debug_log = debug_log;
    this.#_websockets_abortable = new Set<IWebSocketAbortable>();
  }

  /**
   * Fetch API wrapper with some capability restricted and some added
   * functionality: Tries to prevent passing in AbortController signal and
   * adds a centralized abortion mechanism intended to be bound to auth cookie
   * state.
   */
  public async fetch(url: string, arg_init?: Omit<RequestInit, 'signal'>): ReturnType<typeof fetch> {
    if (typeof url !== 'string') {
      throw new Error('Unexpected type of url arg (1st argument): Expected string, got ' + typeof url);
    }
    if (arg_init) {
      if (typeof arg_init === 'object') {
        // to centralize aborting outbound requests
        if ('signal' in arg_init) {
          throw new Error("Unexpected 'signal' passed in fetch init arg (2nd argument): Should be omitted");
        }
      }
    } else {
      arg_init = {};
    }

    const is_auth: boolean = this.#_is_request_auth(url);
    if (is_auth) {
      /* Expecting changes in the session, therefore anything in-flight at
         this point shall be invalidated. */
      this.abort();
    }

    if (!this.#_abort_controller) {
      this.#_abort_controller = { ctl: new AbortController(), requests: new Set<TTrackedFetchApiRequest>() };
    }

    const id: string = self.crypto.randomUUID();
    const tracked_request: TTrackedFetchApiRequest = { id, is_auth, url };
    this.#_abort_controller.requests.add(tracked_request);
    arg_init = Object.assign({}, arg_init, { signal: this.#_abort_controller.ctl.signal });

    this.#_log_debug(
      'Fetch API %d in-flight: Latest: %s %s',
      this.#_abort_controller.requests.size,
      arg_init.method ?? 'GET',
      url,
    );
    this.#_sync_react();
    const promise: Promise<Response> = fetch(url, arg_init);
    promise.then((response) => {
      this.#_log_debug('Response status %d: %s', response.status, response.url);
    });
    promise.finally(() => {
      this.#_abort_controller?.requests.delete(tracked_request);
      this.#_sync_react();
    });
    return promise;
  }

  /**
   * WebSocket connection API (constructor) wrapper with added abort capability
   * because the `AbortController` present in Fetch API does not exist in
   * WebSocket API.
   */
  public connect_websocket(url: URL): WebSocketAbortable {
    this.#_log_debug('Connecting WebSocket...');
    const ws: WebSocketAbortable = new WebSocketAbortable(url);
    this.#_websockets_abortable.add(ws);
    ws.addEventListener('open', () => this.#_websockets_abortable.delete(ws));
    return ws;
  }

  /**
   * Abort any queued or in-flight HTTP requests and WebSocket handshakes that
   * can be aborted.
   */
  public abort(): void {
    // abort WebSocket handshakes
    this.#_abort_websocket_handshakes();

    // abort HTTP requests over Fetch API
    if (this.#_abort_controller?.requests.size) {
      const abortable_count: number = this.#_abort_controller.requests.size;
      this.#_abort_controller.ctl.abort();
      this.#_abort_controller.requests.clear();
      this.#_abort_controller = null;
      this.#_sync_react();
      this.#_log_debug('Aborted %d outbound HTTP requests:', abortable_count);
    }
  }

  /**
   * Determine whether a request to given URL is expected to cause a change
   * in auth cookies store.
   */
  #_is_request_auth(url_path: string): boolean {
    return url_path.startsWith('/auth');
  }

  #_abort_websocket_handshakes(): number {
    let aborted_count = 0;
    for (const socket of this.#_websockets_abortable) {
      socket.abort();
      this.#_websockets_abortable.delete(socket);
      aborted_count++;
    }
    if (aborted_count > 0) {
      this.#_log_debug('Aborted %d outbound WebSocket handshakes', aborted_count);
    }
    return aborted_count;
  }

  #_log_debug(msg_fmt: string, ...args: unknown[]) {
    if (!this.#_debug_log) return;
    /* eslint-disable no-console */
    console.debug('[%s] - ' + msg_fmt, new Date().toISOString(), ...args);
  }

  /**
   * Sync local _ConnectionManager_ state with the React app by sending over the
   * local state.
   *
   * This method should be called whenever a request starts being or stops being
   * in-flight. For example: when a new request is dispatched, or a previously
   * dispatched request terminates at receival of its response, or when a
   * request at any stage is aborted.
   */
  #_sync_react(): void {
    if (!this.#_abort_controller) {
      sync_react({ inflight_fetch_count_api: 0, inflight_fetch_count_auth: 0 });
      return;
    } else {
      const { inflight_fetch_count_api, inflight_fetch_count_auth } = this.#_abort_controller.requests
        .values()
        .reduce<TCMReactState>(
          (acc, cur) => {
            if (cur.is_auth) {
              acc.inflight_fetch_count_auth++;
            } else {
              acc.inflight_fetch_count_api++;
            }
            return acc;
          },
          {
            inflight_fetch_count_api: 0,
            inflight_fetch_count_auth: 0,
          },
        );
      sync_react({ inflight_fetch_count_api, inflight_fetch_count_auth });
      return;
    }
  }
}

/**
 * Get reference to `ConnectionManager` singleton.
 */
function get(): ConnectionManager {
  const instance = ConnectionManager._singleton ?? new ConnectionManager({ debug_log: false });
  return instance;
}

export type { IConnectionManager, IWebSocketAbortable };
export default get;
