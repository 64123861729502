import { INeuroCommit, INeuroDocument, INeuroStimulus } from 'neuro-data-structures';
import { omitControlProps } from 'Utility/documentHandling';
import { TDispatch } from '..';
import { createAction } from '@reduxjs/toolkit';

import { getJWTData } from '../../utility/jwtAuthTools';
import {
  createCommitToDocument,
  createNewDocumentWithCommit,
  deleteDocumentFetcher,
  deleteTaskListFetcher,
  getMyAppDocs,
  getMyAppInvites,
  getMyAppUserIdentity,
  getTaskLists,
  sendMyInvite,
  sendTaskList,
  updateTaskListFetcher,
} from './fetchers';
import { IMySQData } from './myAppGlobals';

const createDummyDocument = (
  documentType: string,
  documentId: string,
  creatorId: string,
  creatorOrg: string,
): INeuroDocument => ({
  documentId,
  documentType,
  createDate: Date.now(),
  creatorId,
  creatorOrg,
  ownerOrg: creatorOrg,
  removeTS: null,
  commits: [],
  lockedFor: null,
  source: null,
  removeInfo: null,
  pastOwners: {},
});
const createDummyCommit = (
  commitId: string,
  creatorId: string,
  creatorOrg: string,
  data: IControlProps | TAnyObject,
): INeuroCommit => ({
  commitId,
  createDate: Date.now(),
  creatorId,
  commitDate: Date.now(),
  creatorOrg,
  data,
  source: null,
  lockedFor: null,
});

const LOAD_DATA = 'neuro-ui/myapp/LOAD_DATA';
const CLEAR_DATA = 'neuro-ui/myapp/CLEAR_DATA';
const NEW_DOCUMENT = 'neuro-ui/myapp/NEW_DOCUMENT';
const NEW_COMMIT = 'neuro-ui/myapp/NEW_COMMIT';
const DELETE_DOCUMENT = 'neuro-ui/myapp/DELETE_DOCUMENT';
const LOAD_TASKLISTS = 'neuro-ui/myapp/LOAD_TASKLISTS';
const CREATE_TASKLIST = 'neuro-ui/myapp/CREATE_TASKLIST';
const UPDATE_TASKLIST = 'neuro-ui/myapp/UPDATE_TASKLIST';
const DELETE_TASKLIST = 'neuro-ui/myapp/DELETE_TASKLIST';
const CREATE_INVITE = 'neuro-ui/myapp/CREATE_INVITE';

const loadDataAction = createAction(LOAD_DATA, (myAppData: IMySQData) => {
  return {
    payload: myAppData,
  };
});

const clearDataAction = createAction(CLEAR_DATA, () => {
  return { payload: null };
});

const newDocumentAction = createAction(NEW_DOCUMENT, (newDocument: INeuroDocument) => {
  return {
    payload: {
      document: newDocument,
    },
  };
});

const newCommitAction = createAction(NEW_COMMIT, (docId: string, commitData: INeuroCommit) => {
  return {
    payload: {
      id: docId,
      data: commitData,
    },
  };
});

const deleteDocumentAction = createAction(DELETE_DOCUMENT, (docId: string) => {
  return {
    payload: {
      id: docId,
    },
  };
});

const loadTasklistsAction = createAction(LOAD_TASKLISTS, (taskLists: Array<ITaskList>) => {
  return {
    payload: taskLists,
  };
});

const createTasklistAction = createAction(CREATE_TASKLIST, (list: ITaskList) => {
  return {
    payload: list,
  };
});

const updateTasklistAction = createAction(UPDATE_TASKLIST, (list: ITaskList) => {
  return {
    payload: list,
  };
});

const deleteTasklistAction = createAction(DELETE_TASKLIST, (list: ITaskList) => {
  return {
    payload: list,
  };
});

const createInviteAction = createAction(CREATE_INVITE, (props: INeuroStimulus) => {
  return {
    payload: props,
  };
});

const loadMyAppData = async (dispatch: TDispatch): Promise<void> => {
  const [sentInvites, tasks, sortedAndMergedDocuments, mysqUserId] = await Promise.all([
    getMyAppInvites<INeuroStimulus>(),
    getTaskLists(),
    getMyAppDocs(),
    getMyAppUserIdentity(),
  ]);
  dispatch(loadDataAction({ sentInvites, mysqUserId, sortedAndMergedDocuments }));
  if (tasks) {
    dispatch(loadTasklistsAction(tasks));
  }
};

const putNewMyDocument = async (docType: string, docData: any, dispatch: TDispatch): Promise<void> => {
  const useruuid = getJWTData()?.useruuid || null;
  const orgid = getJWTData()?.orgid || null;

  createNewDocumentWithCommit(docType, docData).then((res) => {
    if (res) {
      let newDummy = createDummyDocument(docType, res.documentId, useruuid || '', orgid || '');
      newDummy = {
        ...newDummy,
        commits: [createDummyCommit(res.commitId, useruuid || '', orgid || '', docData) as INeuroCommit],
      };
      dispatch(newDocumentAction(newDummy));
      return true;
    }
    return false;
  });
};

const putNewCommit = async (docType: string, docId: string, commitData: any, dispatch: TDispatch): Promise<void> => {
  const commitDataOmitted = omitControlProps(commitData);
  await createCommitToDocument(docType, docId, commitDataOmitted).then((res) => {
    if (res) {
      const useruuid = getJWTData()?.useruuid || null;
      const orgid = getJWTData()?.orgid || null;
      const dummyCommit = createDummyCommit(res.commitId, useruuid || '', orgid || '', commitDataOmitted);
      dispatch(newCommitAction(docId, dummyCommit));
      return true;
    }
    return false;
  });
};

const deleteDocument = async (docType: string, docId: string, dispatch: TDispatch): Promise<void> => {
  deleteDocumentFetcher(docType, docId, { reason: 'Document deletion' }).then((res) => {
    if (res) {
      dispatch(deleteDocumentAction(docId));
      return true;
    }
    return false;
  });
};

const newTasklist = async (list: ITaskList, dispatch: TDispatch): Promise<boolean> => {
  return sendTaskList(list).then((res) => {
    if (res) {
      dispatch(createTasklistAction({ ...list, id: res.id ?? '' }));
      return true;
    }
    return false;
  });
};

const updateTasklist = async (list: ITaskList, dispatch: TDispatch): Promise<boolean> => {
  return updateTaskListFetcher(list).then((res) => {
    if (res) {
      dispatch(updateTasklistAction({ ...list, task_duration: res.task_duration }));
      return true;
    }
    return false;
  });
};

const deleteTasklist = async (list: ITaskList, dispatch: TDispatch): Promise<boolean> => {
  return deleteTaskListFetcher(list).then((res) => {
    if (res) {
      dispatch(deleteTasklistAction(list));
      return true;
    }
    return false;
  });
};

/**
 * Action that sends new invite for patient to download mobile application
 * @param carrier Email address or phone number, depending on medium
 * @param medium Either EMAIL or SSN
 * @param org_id organization id
 * @param dispatch redux dispatch function
 * @param language 2-letter ISO 639 code of the invite recipient language
 * @returns {Promise<number>} Request status code
 */
const newInvite = async (
  carrier: string,
  medium: string,
  org_id: string,
  dispatch: TDispatch,
  language: Locale,
): Promise<number> => {
  const res = await sendMyInvite(carrier, medium, org_id, language);
  if (res.status === 200) {
    const json = (await res.json()) as INeuroStimulus;
    dispatch(createInviteAction(json));
    return 200;
  } else {
    return res.status;
  }
};

export const reduxActions = {
  loadDataAction,
  clearDataAction,
  newDocumentAction,
  newCommitAction,
  deleteDocumentAction,
  loadTasklistsAction,
  createTasklistAction,
  updateTasklistAction,
  deleteTasklistAction,
  createInviteAction,
};

export const actions = {
  loadMyAppData,
  clearDocuments: clearDataAction,
  putNewMyDocument,
  putNewCommit,
  deleteDocument,
  newTasklist,
  updateTasklist,
  deleteTasklist,
  newInvite,
};
