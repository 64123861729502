import { IAddon, IEvent, IItem } from 'Components/sq-graphics/interfaces';
import {
  exists,
  formatPartialDate,
  formatTime,
  isPartialDate,
  isTime,
  partialDateFromDate,
  partialDateToValue,
  sortDate,
  sortPartialDate,
  sortTime,
} from 'neuro-utils';
import { calculateRegimenDose } from 'neuro-calculation-commons';
import { filter, isEmpty, map, path, values } from 'ramda';
import {
  shouldMedBeDrawnByOtherRestrictions,
  findNextDate,
  formatMedicationSubstances,
  formatStrengthStringKeys,
  formatStrengthStringValues,
  getLatestWeight,
  includeMedication,
  renderDosagePerWeight,
  roundValue,
} from 'Components/DashboardGraph/Utils/';
import { getAtcCodesByPlatform, getThemeIdsAsAddonSettings } from 'Routes/Medication/Document/config';
import { getDefaultSettings } from 'Components/sq-graphics/config';
import * as React from 'react';
import { ParkinsonCustomElements } from 'Routes/Medication/Document/HistoryRowData/Medication/components/Regimen/RegimenItems';
import { isParkinsonInfusionType } from 'Routes/Medication/Document/Form/Medication/RegimenDialog/utils/isParkinsonInfusionType';
import { getMedicationUnit } from 'Routes/Medication/utils/medicationUnit';

const administrationDescription = (
  event: IAdministration,
  fm: (id: string) => string,
  messages: Record<string, string>,
): IEvent['description'] => [
  {
    title: fm('medication.administration.endDate'),
    values: formatPartialDate(event.endDate),
    condition: isPartialDate(event.endDate),
  },
  {
    title: fm('medication.administration.startTime'),
    values: formatTime(event.startTime),
    condition: isTime(event.startTime),
  },
  {
    title: fm('medication.administration.endTime'),
    values: formatTime(event.endTime),
    condition: isTime(event.endTime),
  },
  {
    title: fm('medication.administration.doseQuantity'),
    values:
      exists(event.doseQuantity) || exists(event.doseQuantityUnit)
        ? `${exists(event.doseQuantity) ? `${event.doseQuantity} ` : ''}${getMedicationUnit(
            event.doseQuantityUnit,
            messages,
          )}`
        : undefined,
  },
  {
    title: fm('medication.administration.isExtraDose'),
    values: Array.isArray(event.isExtraDose) ? fm(`general.${event.isExtraDose?.[0] ? 'yes' : 'no'}`) : undefined,
  },
  {
    title: fm('medication.administration.additionalInformation'),
    values: event.additionalInformation,
  },
];

const pauseDescription = (event: IMedicationPause, fm: (id: string) => string): IEvent['description'] => [
  {
    title: fm('medication.pausationTime'),
    values: formatTime(event.startTime),
    condition: isTime(event.startTime),
  },
  {
    title: fm('medication.pausationReason'),
    values: event.pausationReason ? fm(`medication.pausationReasons.${event.pausationReason}`) : undefined,
  },
];

const medicationEvents = (
  title: string | undefined,
  type: IEvent['eventType'],
  events: Array<IAdministration | IAdverseEffect | IMedicationEvent | IMedicationPause>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  messages: Record<string, string>,
): IEvent[] => {
  const retEvents: IEvent[] = [];
  let careTitle = '';
  if (events && events.length > 0) {
    events.forEach((event) => {
      let date: Date;
      let time: Time | undefined = undefined;
      if ('date' in event && event.date) {
        date = dateFromPartialUpdateTimeframe(event.date);
        time = 'startTime' in event && event.startTime ? event.startTime : undefined;
      } else if ('startDate' in event && event?.startDate) {
        date = dateFromPartialUpdateTimeframe(event?.startDate);
        time = 'startTime' in event && event.startTime ? event.startTime : undefined;
      } else date = new Date();

      let description: IEvent['description'];
      switch (type) {
        case 'administration': {
          careTitle = fm('graph.administration');
          description = administrationDescription(event as IAdministration, fm, messages);
          break;
        }
        case 'pause': {
          if ('startDate' in event && exists(event.startDate)) {
            careTitle = fm('graph.medicPause');
            description = pauseDescription(event, fm);

            if (event.endDate && event.endDate.every((n) => n)) {
              retEvents.push({
                date: dateFromPartialUpdateTimeframe(event.endDate),
                time: event.endTime,
                eventType: 'continuation',
                title: fm('graph.medicContinuation'),
                description: [
                  {
                    title: fm('medication.continuationTime'),
                    values: formatTime(event.endTime),
                    condition: isTime(event.endTime),
                  },
                ],
              });
            }
          }
          break;
        }
      }

      retEvents.push({
        date: date,
        time: time,
        eventType: type,
        title: (careTitle ? careTitle : title) ?? '',
        description: description,
      });
    });
  }
  return retEvents;
};

const dosagePerWeight = (
  docs: IMeasurement[],
  dosageDate: PartialDate | undefined,
  dailyDose: string | undefined,
  fm: (id: string) => string,
): IEvent['description'] => {
  const dd = dailyDose ? parseFloat(dailyDose) : undefined;
  const latestWeight = getLatestWeight(docs, dosageDate);
  return [
    {
      title: fm('graph.dailyDosePerWeight'),
      values: dd && latestWeight ? Math.round((dd / parseFloat(latestWeight)) * 10000) / 10000 : '',
      condition: exists(dd) && exists(latestWeight),
    },
  ];
};

/**
 * Description for given regimen date and name.
 * @param date - PartialDate
 * @param name - Name
 * @param regimenType - Regimen type will be drawn if it's given
 */
const regimenName = (
  name: string | undefined,
  time: Time | undefined,
  regimenType: string | undefined,
  fm: (id: string) => string,
): IEvent['description'] => [
  {
    title: formatTime(time),
    condition: isTime(time),
  },
  {
    title: `${name ? `${name} ` : ''}${
      regimenType && regimenType === 'solution'
        ? fm(('medication.opts.' + regimenType).toLowerCase()).toLowerCase()
        : ''
    }`,
    condition: exists(name) || (exists(regimenType) && regimenType === 'solution'),
  },
];

/**
 * Create tooltips for default medication events from given data.
 * @param data - Data
 * @param dateFromPartialUpdateTimeframe - Function to convert PartialDate into Date
 */
const defaultRegimenEvent = (
  data: IMedication,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  allDates: Date[],
  dosagePerWeightProps: { docs: IMeasurement[]; atc: string },
  fm: (id: string, values?: { [key: string]: string }) => string,
  messages: Record<string, string>,
): IEvent[] => {
  const events: IEvent[] = [];
  const regimens = (data.regimen || []).filter((item) => item.regimenType === 'default') as Array<
    IRegimenBasics & IRegimenDefault
  >;

  const getStrengthIndex = (s: IStrengths, pad?: boolean): string => {
    const strs = values(s);

    return pad ? strs.join(' / ') : strs.join('/');
  };

  regimens.forEach((regimen) => {
    const dosages = Array.isArray(regimen.dosages) ? regimen.dosages : [];
    const strengths = Array.isArray(regimen.strengths) ? regimen.strengths : [];

    const dailyDose = calculateRegimenDose('default', regimen);
    const parsedDailyDose: string | undefined =
      typeof dailyDose === 'object'
        ? typeof dailyDose[Object.keys(dailyDose)[0]] === 'string'
          ? path([0], dailyDose[Object.keys(dailyDose)[0]].split(' '))
          : undefined
        : undefined;

    const showDosagePerWeight = renderDosagePerWeight({
      docs: dosagePerWeightProps.docs,
      dosageDate: regimen.date,
      atc: dosagePerWeightProps.atc,
    });
    const dosagePerWeightValue: number | undefined = showDosagePerWeight
      ? path([0, 'values'], dosagePerWeight(dosagePerWeightProps.docs, regimen.date, parsedDailyDose, fm))
      : undefined;

    const description: IEvent['description'] = [
      ...(regimenName(data.medicationName, regimen.time, undefined, fm) as []),
      {
        title: formatStrengthStringKeys(strengths[0]),
        condition:
          data.medicationSubstances !== null && data.medicationSubstances !== undefined && strengths.length > 0,
      },
      {
        title: fm('medication.strength'),
        values: strengths.length > 0 ? strengths.slice(0).map((s) => getStrengthIndex(s, true)) : [],
        condition: dosages.length <= 0,
      },
      {
        title: fm('medication.strength'),
        values: strengths.map((s, k) => {
          const strs = values(s);
          return (
            <div key={k}>
              {strs.map((st, i) => (
                <div key={i}>{st + `${strs.length > 1 && i < strs.length - 1 ? ' /' : ''}`}</div>
              ))}
            </div>
          );
        }),
        condition: dosages.length > 0,
      },
      ...(dosages.length > 0
        ? dosages.map((d) => {
            return {
              title:
                isTime(d.from) || isTime(d.to)
                  ? `${formatTime(d.from)}${d.to ? ' - ' : ''}${d.to ? formatTime(d.to) : ''}`
                  : undefined,
              values: strengths.map(
                (s) =>
                  `${
                    d.dosages?.[getStrengthIndex(s)]
                      ? `${d.dosages?.[getStrengthIndex(s)]} ${getMedicationUnit(regimen.unit, messages)}`
                      : '-'
                  }`,
              ),
            };
          })
        : exists(regimen.doses)
          ? exists(regimen.unit)
            ? [
                {
                  title: fm('graph.dosage'),
                  values: `${regimen.doses} ${getMedicationUnit(regimen.unit, messages)}} ${
                    'interval' in regimen &&
                    regimen.interval &&
                    'repeatNumber' in regimen &&
                    exists(regimen.repeatNumber)
                      ? `${fm('medication.regimenDoseInterval', {
                          n: `${regimen.repeatNumber}`,
                        })} ${fm(`medication.opts.${regimen.interval}`)}`.toLowerCase()
                      : ''
                  }`,
                },
              ]
            : [
                {
                  title: fm('graph.dosage'),
                  values: `${fm('medication.regimenDose', { n: (regimen.doses ?? 0).toString() })} ${
                    'interval' in regimen &&
                    regimen.interval &&
                    'repeatNumber' in regimen &&
                    exists(regimen.repeatNumber)
                      ? `${fm('medication.regimenDoseInterval', {
                          n: `${regimen.repeatNumber}`,
                        })} ${fm(`medication.opts.${regimen.interval}`)}`.toLowerCase()
                      : ''
                  }`,
                },
              ]
          : []),
      ...(showDosagePerWeight
        ? (dosagePerWeight(dosagePerWeightProps.docs, regimen.date, parsedDailyDose, fm) as [])
        : []),
    ];
    regimen.date &&
      events.push({
        date: dateFromPartialUpdateTimeframe(regimen.date),
        time: regimen.time,
        eventType: 'regimen',
        title: fm('graph.dosage'),
        description: description,
      });
    roundValue(parsedDailyDose ?? '') &&
      regimen.date &&
      events.push({
        date: dateFromPartialUpdateTimeframe(regimen.date),
        endDate: findNextDate(regimen.date, allDates),
        time: regimen.time,
        eventType: 'dynamic',
        title: roundValue(parsedDailyDose ?? ''),
        secondaryTitle: exists(dosagePerWeightValue)
          ? `(${roundValue(dosagePerWeightValue?.toString() ?? '')})`
          : undefined,
      });
  });

  return events;
};

/**
 * Create tooltips for on demand medication events from given data.
 * @param data - Data
 * @param dateFromPartialUpdateTimeframe - Function to convert PartialDate into Date
 */
const onDemandEvent = (
  data: IMedication,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  messages: Record<string, string>,
): IEvent[] => {
  const events: Array<IEvent> = [];
  const regimens = filter((item: Regimen) => item.regimenType === 'onDemand', data.regimen || []);
  map((regimen: Regimen) => {
    const description = [
      ...(regimenName(data.medicationName, regimen.time, undefined, fm) as []),
      {
        title: fm('medication.opts.onDemand'),
      },
      {
        title: formatStrengthStringKeys((regimen.strengths ?? [])[0]),
        condition:
          data.medicationSubstances !== null &&
          data.medicationSubstances !== undefined &&
          Array.isArray(regimen.strengths) &&
          regimen.strengths.length > 0,
      },
      {
        title: formatStrengthStringValues((regimen.strengths ?? [])[0]),
        condition: Array.isArray(regimen.strengths) && regimen.strengths.length > 0,
      },
      {
        title: fm('medication.doseSize'),
        values: `${'dose' in regimen && regimen.dose} ${
          'unit' in regimen && getMedicationUnit(regimen.unit, messages)
        }`,
        condition: 'dose' in regimen && exists(regimen.dose),
      },
      {
        title: fm('medication.maxDoses'),
        values: `${'maxDoses' in regimen && regimen.maxDoses} ${
          'unit' in regimen && getMedicationUnit(regimen.unit, messages)
        }`,
        condition: 'maxDoses' in regimen && exists(regimen.maxDoses),
      },
    ];
    regimen.date &&
      events.push({
        date: dateFromPartialUpdateTimeframe(regimen.date),
        time: regimen.time,
        eventType: 'regimen',
        title: fm('graph.dosage'),
        description: description,
      });
  }, regimens);
  return events;
};

const customEvent = (
  data: IMedication,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IEvent[] => {
  const events: IEvent[] = [];
  const regimens = (data.regimen || []).filter((item: Regimen) => item.regimenType === 'custom') as Array<
    IRegimenCustom & IRegimenBasics
  >;
  regimens.forEach((regimen) => {
    const description = [
      ...(regimenName(data.medicationName, regimen.time, undefined, fm) as []),
      {
        title: fm('medication.opts.custom'),
      },
      {
        title: formatStrengthStringKeys((regimen.strengths ?? [])[0]),
        condition:
          data.medicationSubstances !== null &&
          data.medicationSubstances !== undefined &&
          Array.isArray(regimen.strengths) &&
          regimen.strengths.length > 0,
      },
      {
        title: formatStrengthStringValues((regimen.strengths ?? [])[0]),
        condition: Array.isArray(regimen.strengths) && regimen.strengths.length > 0,
      },
      {
        title: <ParkinsonCustomElements d={data} regimen={regimen} />,
        condition: !!isParkinsonInfusionType(data),
      },
      {
        title: regimen.regimenDetails,
        condition: exists(regimen.regimenDetails),
      },
      {
        title: regimen.regimenDetailsOther,
        condition: exists(regimen.regimenDetailsOther),
      },
    ];
    regimen.date &&
      events.push({
        date: dateFromPartialUpdateTimeframe(regimen.date),
        time: regimen.time,
        eventType: 'regimen',
        title: fm('graph.dosage'),
        description: description,
      });
  });
  return events;
};

const singleDoseEvent = (
  data: IMedication,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  messages: Record<string, string>,
): IEvent[] => {
  const events: IEvent[] = [];
  const regimens = (data.regimen || []).filter((item: Regimen) => item.regimenType === 'single-dose');
  regimens.forEach((regimen) => {
    const description = [
      ...(regimenName(data.medicationName, regimen.time, undefined, fm) as []),
      {
        title: fm('medication.opts.single-dose'),
      },
      {
        title: formatStrengthStringKeys((regimen.strengths ?? [])[0]),
        condition:
          data.medicationSubstances !== null &&
          data.medicationSubstances !== undefined &&
          Array.isArray(regimen.strengths) &&
          regimen.strengths.length > 0,
      },
      {
        title: fm('medication.strength'),
        condition: Array.isArray(regimen.strengths) && regimen.strengths.length > 0,
        values: formatStrengthStringValues((regimen.strengths ?? [])[0]),
      },
      {
        title: fm('graph.dosage'),
        condition: 'dose' in regimen && exists(regimen.dose),
        values: `${'dose' in regimen && regimen.dose} ${
          'unit' in regimen && getMedicationUnit(regimen.unit, messages)
        }`,
      },
      {
        title: regimen.regimenDetailsOther,
        condition: exists(regimen.regimenDetailsOther),
      },
    ];
    regimen.date &&
      events.push({
        date: dateFromPartialUpdateTimeframe(regimen.date),
        time: regimen.time,
        eventType: 'regimen',
        title: fm('graph.dosage'),
        description: description,
      });
  });
  return events;
};

const otherRegimenEvent = (
  data: IMedication,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IEvent[] => {
  const events: IEvent[] = [];
  const regimens = (data.regimen || []).filter((item: Regimen) => item.regimenType === 'other');
  regimens.forEach((regimen) => {
    const description = [
      ...(regimenName(data.medicationName, regimen.time, undefined, fm) as []),
      {
        title: fm('medication.regimenTypes.other'),
      },
      {
        title: formatStrengthStringKeys((regimen.strengths ?? [])[0]),
        condition:
          data.medicationSubstances !== null &&
          data.medicationSubstances !== undefined &&
          Array.isArray(regimen.strengths) &&
          regimen.strengths.length > 0,
      },
      {
        title: fm('medication.strength'),
        condition: Array.isArray(regimen.strengths) && regimen.strengths.length > 0,
        values: formatStrengthStringValues((regimen.strengths ?? [])[0]),
      },
      {
        title: fm('medication.regimenOtherInstructions'),
        condition: 'instructions' in regimen && exists(regimen.instructions),
        values: 'instructions' in regimen && regimen.instructions,
      },
      {
        title: regimen.regimenDetailsOther,
        condition: exists(regimen.regimenDetailsOther),
      },
    ];
    regimen.date &&
      events.push({
        date: dateFromPartialUpdateTimeframe(regimen.date),
        time: regimen.time,
        eventType: 'regimen',
        title: fm('graph.dosage'),
        description: description,
      });
  });
  return events;
};

/**
 * Create tooltips for ending events of medication from given data
 * @param data - Data
 * @param dateFromPartialUpdateTimeframe - Function to convert PatialDate into Date
 */
const medicationEndEvent = (
  data: IMedication,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IEvent[] => {
  const events: IEvent[] = [];
  // Find latest regimen to get the strength at the time of the end of the medication
  const regimens = Array.isArray(data.regimen)
    ? data.regimen.filter((item: Regimen) => item.regimenType === 'default')
    : [];
  const latestRegimen = regimens
    ?.slice()
    .sort((r1, r2) => sortPartialDate(r2.date, r1.date))
    .find((regimen) => Array.isArray(regimen.strengths));
  const strengths = latestRegimen && Array.isArray(latestRegimen.strengths) ? latestRegimen.strengths : [];
  const description = [
    ...(regimenName(data.medicationName, data.endTime, undefined, fm) as []),
    {
      title: formatStrengthStringKeys(strengths[0]),
      condition: strengths.length > 0,
    },
    {
      title: fm('medication.endedReason'),
      values: Array.isArray(data.endReason)
        ? data.endReason.map((reason: string) => `${fm(`medication.opts.${reason}`)}\n`).join('')
        : undefined,
      condition: Array.isArray(data.endReason) && data.endReason.length > 0,
    },
    {
      title: fm('medication.endedReasonOther'),
      values: data.endReasonOther,
      condition: exists(data.endReasonOther),
    },
  ];
  data.endDate &&
    events.push({
      date: dateFromPartialUpdateTimeframe(data.endDate),
      time: data.endTime,
      eventType: 'regimen',
      title: fm('graph.end'),
      description: description,
    });
  return events;
};

const medicationAdverseEffectEvents = (
  docs: Array<IMedicationAdverseEvent>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IEvent[] => {
  if (!docs || docs.length === 0) return [];
  const events: IEvent[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    const effects: string[] = [];
    if ('adverseEffects' in d) {
      d.adverseEffects?.forEach((e: IAdverseEffect) => {
        if (e.eventName) {
          if (e.eventName === 'Muu haittavaikutus' || e.eventName === 'Other adverse effect') {
            effects.push(`${e.eventName}${d.otherAdverseEffect ? ` (${d.otherAdverseEffect})` : ''}`);
          } else effects.push(e.eventName);
        }
      });
    }
    d.date &&
      events.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        eventType: 'adverseEffect',
        title: fm('graph.adverseEffect'),
        description: [
          {
            title: (effects ?? []).join(', ') + (d.eventDetails ? ' – ' + d.eventDetails : ''),
            condition: Array.isArray(effects) && effects.length > 0,
          },
        ],
      });
  });
  return events;
};

// MS has medications that have the same ATC codes but different configurations
const msNameDependentMedications = ['AVONEX', 'BETAFERON', 'REBIF', 'EXTAVIA', 'PLEGRIDY'];

/**
 * Get an addon theme ID for a given medication.
 *
 * @param medDoc Medication document.
 * @param platform StellarQ UI platform.
 * @returns Addon theme ID.
 */
const getThemeIdForMedication = (
  medDoc: IMedication,
  platform: string,
  medicationSettings: IOrganizationNeuroSettings['medicationSettings'] | undefined,
): string | undefined => {
  if (platform !== 'ms' && platform !== 'mgravis') return;

  if (medDoc.isClinicalStudy && !isEmpty(medDoc.isClinicalStudy)) {
    return 'clinicalStudy';
  }

  // Find indexor of addonSettings matching the medDoc.medicationName or medDoc.atc
  else {
    const { medicationName, atc } = medDoc;
    if (typeof medicationName !== 'string' || typeof atc !== 'string') {
      return undefined;
    }

    if (platform === 'mgravis') {
      if (getAtcCodesByPlatform(medicationSettings, 'mgravis', { categories: ['immunological'] }).includes(atc)) {
        return 'mgravisImmunological';
      } else if (getAtcCodesByPlatform(medicationSettings, 'mgravis', { categories: ['symptomatic'] }).includes(atc)) {
        return 'mgravisSymptomatic';
      } else {
        return undefined;
      }
    }

    const themeIdsAsAddonSettings = getThemeIdsAsAddonSettings(medicationSettings);

    // Do not require exact match since medication name might contain specifications like "(eräkirjattava)"
    const matchingName = msNameDependentMedications.find((name) =>
      name.toLowerCase().includes(medicationName.toLowerCase()),
    );
    // If matching name is found, use medication name for themeId
    if (matchingName) {
      const matchingNameIndexor = Object.keys(getDefaultSettings(themeIdsAsAddonSettings).addonSettings).find(
        (themeId) => {
          return new RegExp(themeId, 'i').test(matchingName);
        },
      );
      return matchingNameIndexor;
    }
    // Else use ATC code
    const matchingAtcIndexor = Object.keys(getDefaultSettings(themeIdsAsAddonSettings).addonSettings).find(
      (themeId) => {
        return new RegExp(themeId, 'i').test(atc);
      },
    );
    return matchingAtcIndexor;
  }
};

/**
 * Create medication events to graph from given data.
 * @param doc - Data
 * @param dateFromPartialUpdateTimeframe - Function to convert PatialDate into Date
 */
export const convertMedicationToTimeline = (
  docs: Array<IMedication>,
  allDocs: Array<IControlProps>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  platform: Platform | null,
  medicationSettings: IOrganizationNeuroSettings['medicationSettings'] | undefined,
  fm: (id: string) => string,
  messages: Record<string, string>,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  const adverseEffectDocs = allDocs.filter((d) => d._type === 'adverseEffect') as IMedicationAdverseEvent[];
  const noMedPeriodDocs = allDocs.filter((d) => d._type === 'periodWithoutMedication') as IPeriodWithoutMedication[];
  const dVitaminDocs = (allDocs.filter((d) => d._type === 'otherTreatment') as IMedicationOtherTreatment[]).filter(
    (ot) => ot.type === 'vitaminD',
  );
  docs
    .sort((d1, d2) => {
      return sortPartialDate(d2?.startDate, d1?.startDate);
    })
    .sort((d1, d2) => {
      if (isPartialDate(d1?.usageStartDate) && isPartialDate(d2?.usageStartDate)) {
        return sortPartialDate(d2.usageStartDate, d1.usageStartDate);
      }
      return 0;
    })
    .forEach((doc) => {
      const isClinicalStudy = path(['isClinicalStudy', 0], doc) === true;
      if (
        !includeMedication(
          { atc: doc.atc, medicationName: doc.medicationName },
          getAtcCodesByPlatform(medicationSettings, platform ?? undefined),
          isClinicalStudy,
          platform || '',
        ) ||
        !shouldMedBeDrawnByOtherRestrictions(platform || '', allDocs, {
          atc: doc.atc,
          medicationName: doc.medicationName,
        })
      )
        return;
      if (!isPartialDate(doc.startDate)) return;

      const start: Date = dateFromPartialUpdateTimeframe(
        doc.usageStartDate ? doc.usageStartDate : doc.startDate,
        undefined,
        true,
      );

      const nowPartial = partialDateFromDate(new Date());
      const end: Date | undefined = doc.hasEnded?.[0]
        ? dateFromPartialUpdateTimeframe(doc.endDate || nowPartial, undefined, true)
        : undefined;

      const aeDocs: Array<IMedicationAdverseEvent> = [];

      // Filter adverse effect -documents
      adverseEffectDocs.forEach((d) => {
        if (doc._id === d.medicationId) {
          aeDocs.push(d);
        }
      });

      const medEvents: IEvent[] = medicationAdverseEffectEvents(aeDocs, dateFromPartialUpdateTimeframe, fm);

      const administrations: IEvent[] = medicationEvents(
        doc.medicationName,
        'administration',
        doc.administration || [],
        dateFromPartialUpdateTimeframe,
        fm,
        messages,
      );

      const pauses: IEvent[] = medicationEvents(
        doc.medicationName,
        'pause',
        doc.pauses || [],
        dateFromPartialUpdateTimeframe,
        fm,
        messages,
      );

      const onDemandEvents: IEvent[] =
        (doc.regimen || []).length > 0 ? onDemandEvent(doc, dateFromPartialUpdateTimeframe, fm, messages) : [];
      const customEvents: IEvent[] =
        (doc.regimen || []).length > 0 ? customEvent(doc, dateFromPartialUpdateTimeframe, fm) : [];
      const singleDoseEvents: IEvent[] =
        (doc.regimen || []).length > 0 ? singleDoseEvent(doc, dateFromPartialUpdateTimeframe, fm, messages) : [];
      const otherRegimenEvents: IEvent[] =
        (doc.regimen || []).length > 0 ? otherRegimenEvent(doc, dateFromPartialUpdateTimeframe, fm) : [];
      const endEvents: IEvent[] = end ? medicationEndEvent(doc, dateFromPartialUpdateTimeframe, fm) : [];

      const regimenDates: Date[] = (doc.regimen || []).map((r) =>
        'date' in r && r.date ? dateFromPartialUpdateTimeframe(r.date) : new Date(),
      );

      const allDates: Date[] = [
        ...[...medEvents, ...administrations, ...endEvents, ...pauses].map((e) => e.date),
        ...regimenDates,
      ];

      const defaultEvents: IEvent[] =
        (doc.regimen || []).length > 0
          ? defaultRegimenEvent(
              doc,
              dateFromPartialUpdateTimeframe,
              allDates,
              { docs: allDocs, atc: doc.atc ?? '' },
              fm,
              messages,
            )
          : [];

      const events: IEvent[] = [
        ...medEvents,
        ...defaultEvents,
        ...administrations,
        ...endEvents,
        ...onDemandEvents,
        ...customEvents,
        ...singleDoseEvents,
        ...otherRegimenEvents,
        ...pauses,
      ];
      events.sort((n1, n2) => sortTime(n2.time, n1.time)).sort((n1, n2) => sortDate(n1.date, n2.date));
      const items: IItem[] = [];
      const activeSubstances: string | undefined =
        doc.medicationSubstances && formatMedicationSubstances(doc.medicationSubstances);
      const baseItem: IItem = {
        start: new Date(),
        title:
          doc.isClinicalStudy?.[0] === true
            ? fm('medication.clinicalStudyMedication') + `: ${doc.medicationName}`
            : doc.medicationName ?? '',
        description: isPartialDate(doc.usageStartDate)
          ? [
              { title: fm('medication.start'), values: formatPartialDate(doc.startDate) },
              { title: fm('medication.substances'), values: activeSubstances },
            ]
          : activeSubstances,
      };

      if (!doc.pauses || doc.pauses.length === 0) {
        items.push({
          ...baseItem,
          start: start,
          end: end,
        });
      } else {
        const sortedPauses: Array<IMedicationPause> = doc.pauses
          .slice()
          .sort((a: IMedicationPause, b: IMedicationPause) => sortPartialDate(a.startDate, b.startDate));
        sortedPauses.forEach((p: IMedicationPause, index: number) => {
          const pushTail = (): void => {
            // push the very last item ending in medication end
            p.endDate &&
              p.endDate.every((n) => n) &&
              items.push({
                ...baseItem,
                start:
                  partialDateToValue(p.endDate) > start.valueOf() ? dateFromPartialUpdateTimeframe(p.endDate) : start,
                end,
              });
          };
          let pStart: PartialDate | undefined = undefined;
          switch (index) {
            case 0:
              // from medication start till first pause start
              p.startDate &&
                partialDateToValue(p.startDate) > start.valueOf() &&
                items.push({
                  ...baseItem,
                  start,
                  end: dateFromPartialUpdateTimeframe(p.startDate),
                });
              // case only one pause
              if (sortedPauses.length === 1) pushTail();
              break;
            case sortedPauses.length - 1:
              // last segment before tail
              pStart = sortedPauses?.[index - 1]?.endDate;
              p.startDate &&
                pStart &&
                items.push({
                  ...baseItem,
                  start: dateFromPartialUpdateTimeframe(pStart),
                  end: dateFromPartialUpdateTimeframe(p.startDate),
                });
              pushTail();
              break;
            default:
              // pauses in between
              pStart = sortedPauses?.[index - 1]?.endDate;
              p.startDate &&
                pStart &&
                items.push({
                  ...baseItem,
                  start: dateFromPartialUpdateTimeframe(pStart),
                  end: dateFromPartialUpdateTimeframe(p.startDate),
                });
          }
        });
      }
      let id = '';
      if (doc.medicationName) id = id + doc.medicationName;
      if (doc.dosageForm) id = id + doc.dosageForm;
      addons.push({
        id,
        title: doc.medicationName ?? '',
        titleDescription: [
          formatPartialDate(doc.startDate),
          isTime(doc.startTime) ? formatTime(doc.startTime) : undefined,
          doc.dosageForm,
          doc.medicationSubstances ? formatMedicationSubstances(doc.medicationSubstances) : undefined,
        ]
          .filter((value) => value)
          .map((value) => `${value}\n`)
          .join(''),
        items,
        events: events,
        themeId: getThemeIdForMedication(doc, platform || '', medicationSettings),
      });

      return;
    });
  noMedPeriodDocs.forEach((d) => {
    if (!isPartialDate(d.startDate)) return;
    const noMedPerEvents: IEvent[] = [];
    const noMedItems: IItem[] = [];
    noMedItems.push({
      start: dateFromPartialUpdateTimeframe(d.startDate),
      end: d.endDate && dateFromPartialUpdateTimeframe(d.endDate),
      title: fm('medication.periodWithoutMedication.title'),
      description: d.reason ? `${fm(`medication.periodWithoutMedication.opts.${d.reason}`)}` : '',
    });
    noMedPerEvents.push({
      date: dateFromPartialUpdateTimeframe(d.startDate),
      eventType: 'start',
      title: fm('medication.periodWithoutMedication.start'),
      description: d.reason ? `${fm(`medication.periodWithoutMedication.opts.${d.reason}`)}` : '',
    });
    d.endDate &&
      isPartialDate(d.endDate) &&
      noMedPerEvents.push({
        date: dateFromPartialUpdateTimeframe(d.endDate),
        eventType: 'end',
        title: fm('medication.periodWithoutMedication.end'),
      });
    addons.push({
      id: 'noMedicationPeriod',
      themeId: 'noMedication',
      title: fm('medication.periodWithoutMedication.periodWithoutMedications'),
      events: noMedPerEvents,
      items: noMedItems,
    });
    return;
  });
  dVitaminDocs.forEach((d) => {
    if (!isPartialDate(d.startDate)) return;
    const dVitEvents: IEvent[] = [];
    const dVitItems: IItem[] = [];

    const frequency = (reg: IVitaminRegimen) =>
      reg.frequency === 'onceADay' || reg.frequency === 'twiceADay'
        ? fm(`medication.otherTreatment.opts.${reg.frequency}`)
        : reg.frequency === 'other'
          ? reg.otherFrequency
          : undefined;

    const dosageAndFrequency = (reg: IVitaminRegimen) =>
      reg.dosage || frequency(reg)
        ? `${reg.dosage ? reg.dosage : frequency(reg) ? '' : '-'} ${
            frequency(reg) ? (reg.dosage ? frequency(reg)?.toLowerCase() : frequency(reg)) : reg.dosage ? '' : '-'
          }`
        : undefined;

    dVitItems.push({
      start: dateFromPartialUpdateTimeframe(d.startDate),
      end: d.endDate && dateFromPartialUpdateTimeframe(d.endDate),
      title: fm('medication.otherTreatment.opts.vitaminD'),
    });

    'regimen' in d &&
      (d.regimen || []).forEach((regimen: IVitaminRegimen) => {
        regimen.date &&
          dVitEvents.push({
            date: dateFromPartialUpdateTimeframe(regimen.date),
            title: fm('medication.otherTreatment.opts.vitaminD'),
            description: [
              {
                title: fm('medication.regimen'),
                values: dosageAndFrequency(regimen),
              },
            ],
            eventType: 'start',
          });
      });

    d.endDate &&
      isPartialDate(d.endDate) &&
      dVitEvents.push({
        date: dateFromPartialUpdateTimeframe(d.endDate),
        title: fm('medication.otherTreatment.opts.vitaminD'),
        eventType: 'end',
        description: [
          {
            title: fm('medication.ended'),
          },
        ],
      });
    addons.push({
      id: 'dVitamin',
      themeId: 'd-vitamin',
      title: fm('medication.otherTreatment.opts.vitaminD'),
      events: dVitEvents,
      items: dVitItems,
    });
    return;
  });

  const getEarliestDate = (addon: IAddon): Date | undefined => {
    return [...(addon.items ?? []).map((i) => i.start), ...(addon.events ?? []).map((e) => e.date)].sort((a, b) =>
      sortDate(a, b),
    )[0];
  };

  addons.sort((a, b) => sortDate(getEarliestDate(b), getEarliestDate(a)));

  return addons.length > 0 ? addons : undefined;
};
